
import config from './config'
import axios from 'axios'
import { Keypoints } from './skeleton-lib/keypoints'
import manager from './Manager'
import { DataDesc, EVideoDesc, ExamMetrics,RVAnnAnn } from './api_types'



function mkKeypoints(keypoints: any): Keypoints {
    const kp = Keypoints.fromJSON(keypoints)
    for (let point of kp.absent()) {
        kp[point] = undefined
    }
    return kp;
}

class Backend {

    private _post(url: string, data: any) {
        //print(`POST ${url}`)
        return axios.post(config.backendURL + url, data, {
            headers: {
                Authorization: 'Token ' + manager.user?.token
            }
        })
    }

    private _get(url: string) {
        //print(`GET ${url}`)
        return axios.get(config.backendURL + url, {
            headers: {
                Authorization: 'Token ' + manager.user?.token
            }
        })
    }

    private async get(url: string) {
        try {
            const resp = await this._get(url)
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
    }


    private async post(url: string, data: any) {
        try {
            const resp = await this._post(url, data)
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
    }


    async login(token: string) {
        try {
            const url = config.backendURL + "/api/login/"
            const resp = await axios.post(url, { token })
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
    }

    async checkToken(token: string) {
        try {
            const url = config.backendURL + "/api/check-token/"
            const resp = await axios.post(url, { token })
            if (resp.data.status === "ok") {
                return resp.data
            } else {
                return null
            }
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
    }

    async getData(): Promise<DataDesc[]> {
        const url = "/api/todo"
        try {
            const resp = await this._get(url);
            const data: DataDesc[] = await resp.data

            for (let p of data) {
                p.keypoints = mkKeypoints(p.keypoints)
                if (p.exam) {
                    p.exam = mkKeypoints(p.exam)
                }
            }
            manager.stats = data[0].stats
            return data
        } catch (e) {
            console.log("Error Catched", e)
            return []
        }
    }

    async getDataAid(aid: number): Promise<DataDesc> {
        console.log(`getDataAid equest aid= ${aid}`, aid)
        const data: DataDesc = await this.post("/api/aid/", { aid })
        data.keypoints = mkKeypoints(data.keypoints)
        return data
    }
    async getDataHash(hash: string): Promise<DataDesc> {
        const data: DataDesc = await this.post("/api/hash/", { hash })
        data.keypoints = mkKeypoints(data.keypoints)
        return data
    }
    async getDataSample(q: any): Promise<DataDesc> {
        const data: DataDesc = await this.post("/api/sample/", q)
        data.keypoints = mkKeypoints(data.keypoints)
        return data
    }



    async getQCData2(query: string): Promise<DataDesc[]> {
        try {
            const resp = await this._get("/api/qc2/" + query)
            const respData = resp.data
            console.log(respData)
            if (respData.status === "ok") {
                return respData.data
            } else {
                return []
            }
        } catch (e) {
            console.log("Error Catched", e)
            return []
        }
    }



    async getQCData(query: any): Promise<DataDesc[]> {
        try {
            const resp = await this._post("/api/qc/", query)
            const respData = resp.data
            console.log(respData)
            if (respData.status === "ok") {
                return respData.data
            } else {
                return []
            }
        } catch (e) {
            console.log("Error Catched", e)
            return []
        }
    }
    async saveData(kp: Keypoints, aid: number, flaggedout: boolean = false) {
        const keypoints = kp.toJSON();
        const respData = await this.post("/api/save/", { aid, keypoints, flaggedout })
        //print("saveData returned", respData)
        return respData.aid as number
    }

    async saveHiddenExam(kp: Keypoints, aid: number) {
        const keypoints = kp.toJSON();
        const respData = await this.post("/api/save_hidden_exam/", { aid, keypoints })
        return respData.aid as number
    }

    async saveExam(aid: number) {
        return await this.post("/api/save_exam/", { aid })
    }

    async delete(aid: number) {
        return await this.post("/api/dellll/", { aid })
    }

    async saveExamAttempt(kp: Keypoints, aid: number, metrics: ExamMetrics) {
        try {
            const resp = await this._post("/api/save_exam_attempt/", {
                aid: aid,
                keypoints: kp.toJSON(),
                metrics: metrics
            })
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
    }



    async stats() {
        return await this.get("/api/stats/")
        /*
        try {
            const resp = await this.get("/api/stats/")
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
        */
    }

    async user_stats(subset: string) {
        return await this.get(`/api/user_stats/?subset=${subset}`)
        /*
        try {
            const resp = await this.get(`/api/user_stats/?subset=${subset}`)
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
        */
    }

    async faces_summary(): Promise<any[]> {
        return await this.get(`/api/faces/summary/`)
    }

    async faces_details(face_id: string): Promise<any> {
        return await this.get(`/api/faces/details/${face_id}`)
    }

    async dataset_stats(ver:string) {
        return await this.get(`/api/dataset_stats/?ver=${ver}`)
        /*
        try {
            const resp = await this.get("/api/dataset_stats/")
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
        */
    }

    async vsave(sid: number, ann: string) {
        this.post("/api/vsave/", { sid, ann })
    }

    async vtodo() {
        return await this.get("/api/vtodo/")
    }

    
    async rvann_todo() {
        return await this.get("/api/rvann-todo/")
    }
    async rvann_save(rvid: number, ann: RVAnnAnn) {
        console.log("rvann_save", rvid)
        this.post("/api/rvann-save/", {rvid, ann})
    }


    async peann_save(peid: number, ann: any) {
        console.log("peann_save>>>>>>>>>>>", peid,ann)
        await this.post("/api/peann-save/", {peid, ann})
    }
    async peann_query(query: any) {
        console.log("peann_query>>>>>>>>>>>", query)
        return await this.post("/api/peann-query/", query)
    }

    async peann_stats() {
        return await this.get(`/api/peann-stats/`)
    }

    async peann_todo() {
        return await this.get("/api/peann-todo/")
    }
    
    async peann_qcdata(query: string) {
        return await this.get("/api/peann-qcdata/"+ query)
    }
    

    

    async feedback_todo() {
        return await this.get("/api/feedback-todo/")
    }

    async feedback_save(fid: number, ann: any) {
        console.log("feedback_save>>>>>>>>>>>", fid,ann)
        await this.post("/api/feedback-save/", {fid, ann})
    }




    async evideoTodo(): Promise<EVideoDesc | null> {
        return await this.get("/api/evideo/todo/")
        /*
        try {
            const resp = await this.get("/api/evideo/todo/")
            return resp.data
        } catch (e) {
            console.log("Error Catched", e)
            return null
        }
        */
    }

    async evideoSave(uuid: string, ann: string) {
        return await this.post("/api/evideo/save/", { uuid, ann })
    }

    async updateUserVersion(uid: number, ver: string) {
        return await this.post("/api/update_user_ver/", { uid, ver })
    }

}

const backend = new Backend()
export default backend
