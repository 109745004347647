import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable } from "mobx";

import {  PointName } from '../../skeleton-lib/keypoints'
import {predictedHeight, heightNorm} from '../../skeleton-lib/height'
import { drawSkeleton, XColorScheme } from '../../skeleton-lib/skeleton-vis'
//import assert from 'assert';
import { DataDesc, ExamMetrics } from '../../api_types';

import {min, max, examMetrics, assert} from '../../utils'

import * as _ from "lodash"
import { Controller } from './Controller';

type ExamViewProps = {   
    data: DataDesc ,
    image: HTMLImageElement 
    annotator: any,
    controller:Controller
}

export const blueColorScheme   = new XColorScheme(undefined,undefined,"cyan",5,6)


interface LegendLineProps {
    color:string
}

function LegendLine({color}:LegendLineProps) {
    return (
            <svg height="30" width="100">
               <circle cx="10" cy="15" r="6" stroke={color} fill={color}/>
               <line x1="10" y1="15" x2="90" y2="15" stroke={color} stroke-width="4" />
               <circle cx="90" cy="15" r="6" stroke={color}  fill={color} />
            </svg>

    )
}




class ExamView extends Component<ExamViewProps> {
    outer: HTMLElement | null = null
    canvasRef: HTMLCanvasElement | null = null

    
    width: number = 0
    height: number = 0
    colorScheme : any
    
    viewX: number = 0
    viewY: number = 0
    viewFactor: number = 1


    b0x: number = 0
    b0y: number = 0
    dragging = false
    movingPoint: PointName | null = null

    boxZoomActive = false
    boxZoomX1 = 0
    boxZoomY1 = 0
    boxZoomX2 = 0
    boxZoomY2 = 0

    /*
    iou = 0
    rmsd = 0
    averageError = 0
    maxError = 0
    pass = false
    */

    metrics : ExamMetrics = new ExamMetrics()


    constructor(props:ExamViewProps) {
        super(props)

 
        makeObservable(this, {
            metrics : observable
        });

    }


    reset() {
        this.viewFactor = 1
        this.viewX = 0
        this.viewY = 0
    }

    view2image(x: number, y: number): [number, number] {
        return [x / this.viewFactor + this.viewX,
        y / this.viewFactor + this.viewY]

    }
    
    setViewX(v: number) {
        this.viewX = v
        if (this.viewX < 0 || this.maxViewX < 0) {
            this.viewX = 0
        } else {
            if (this.viewX > this.maxViewX) {
                this.viewX = this.maxViewX
            }
        }
    }

    setViewY(v: number) {
        this.viewY = v;
        if (this.viewY < 0 || this.maxViewY < 0) {
            this.viewY = 0
        } else {
            if (this.viewY > this.maxViewY) {
                this.viewY = this.maxViewY
            }
        }
    }

    setViewFactor(v: number) {
        this.viewFactor = v
        if (this.viewFactor < this.minviewFactor()) {
            this.viewFactor = this.minviewFactor()
        }
    }

    minviewFactor(): number {
        return min(this.width / this.props.image!.width,
            this.height / this.props.image!.height)

    }

    get maxViewX(): number {
        return (this.props.image!.width * this.viewFactor - this.width) / this.viewFactor
    }

    get maxViewY(): number {
        return (this.props.image!.height * this.viewFactor - this.height) / this.viewFactor
    }
      
    initZoom() {
        this.setViewFactor(this.minviewFactor())
    }


    onMouseDown = (e: any) => {
        if (e.button === 2) {
            this.startBoxZoom(e.clientX, e.clientY)
            return
        }

        if (e.button === 0) {

            this.b0x = e.clientX
            this.b0y = e.clientY
            this.dragging = true
        }
    }




    onMouseMove = (e: any) => {

        if (this.boxZoomActive) {

            this.updateBoxZoom(e.clientX, e.clientY)
            return
        }


        if (this.dragging && e.button === 0) {

            const dx = e.clientX - this.b0x
            const dy = e.clientY - this.b0y

            this.setViewX(this.viewX - dx / this.viewFactor)
            this.setViewY(this.viewY - dy / this.viewFactor)

            this.b0x = e.clientX
            this.b0y = e.clientY

            this.draw()
            return
        }

    }





    onMouseLeave = (e: any) => {
        // print("onMouseLeave")
        if (this.boxZoomActive) {
            this.boxZoomActive = false
            this.draw()
            return
        }
    }

    onMouseUp = (e: any) => {
        //console.log("onMouseUp",e.button)
        if (e.button === 2) {
            this.updateBoxZoom(e.clientX, e.clientY)
            this.stopBoxZoom()
            return
        }



        if (this.dragging && e.button === 0) {
            const dx = e.clientX - this.b0x
            const dy = e.clientY - this.b0y
            this.viewX -= dx / this.viewFactor
            this.viewY -= dy / this.viewFactor
            this.draw()
            this.dragging = false
        }
    }

    checkAnnotation() {
        assert(this.props.data.keypoints)
        assert(this.props.data.exam)

        const exam = this.props.data.exam
        const keypoints = this.props.data.keypoints
        const height = predictedHeight(exam)!;
        this.metrics = examMetrics(keypoints,exam)
        const nodeColor =  new Map<PointName,string>()
        
        for(let point of keypoints.points()) {
            if (exam.hasPoint(point)) {
                const d_px = exam.get(point)!.dist( keypoints.get(point)! )
                const d = heightNorm(d_px, height)

                let color
                if (d>4) {
                    color = "red"
                } else if (d>2) {
                    color = "yellow"
                } else {
                    color = "green"
                }
                nodeColor.set(point,color);
            } else {
                nodeColor.set(point,"red");
            }
        }

        this.colorScheme = new XColorScheme(nodeColor,undefined,"red",3,5)
        for(let i=0; i < this.colorScheme.lineColor.length; i++) {
            let [p1,p2] = this.colorScheme.lineColor[i][0]

            this.colorScheme.lineColor[i][1] = "yellow"   

            if (nodeColor.get(p1)==="green" && nodeColor.get(p2)==="green"){
                this.colorScheme.lineColor[i][1] = "green"   
            }
            if (nodeColor.get(p1)==="red" || nodeColor.get(p2)==="red"){
                this.colorScheme.lineColor[i][1] = "red"   
            }

        }
    }
    zoomIn = () => {
        this.viewFactor *= 1.1
        this.setViewX(this.viewX + 0.05 * this.props.image.width)
        this.setViewY(this.viewY + 0.05 * this.props.image.height)
        this.draw()
    }
    zoomOut = () => {
        this.setViewFactor(this.viewFactor / 1.1)
        this.setViewX(this.viewX)
        this.setViewY(this.viewY)
        this.draw()
    }

    zoomBox() {
        const vx1 = max(0, min(this.boxZoomX1, this.boxZoomX2))
        const vx2 = max(this.boxZoomX1, this.boxZoomX2)
        const vy1 = max(0, min(this.boxZoomY1, this.boxZoomY2))
        const vy2 = max(this.boxZoomY1, this.boxZoomY2)
        const [ix1, iy1] = this.view2image(vx1, vy1)
        const [ix2, iy2] = this.view2image(vx2, vy2)

        const factor = min(this.width / (ix2 - ix1), this.height / (iy2 - iy1))
        //print(factor, this.viewFactor, this.bodyOffset /(ix2 - ix1),   this.height/(iy2 - iy1))

        this.setViewFactor(factor)
        this.setViewX(ix1)
        this.setViewY(iy1)


    }

    onWheel = (e: any) => {
        if (e.deltaY > 0) {
            this.zoomOut()
        } else if (e.deltaY < 0) {
            this.zoomIn()
        }
    }

    initGeometry() {
        assert(this.outer)
        assert(this.canvasRef)
        this.width = this.outer.clientWidth
        this.height = this.outer.clientHeight 
        this.canvasRef.width = this.outer.clientWidth
        this.canvasRef.height = this.outer.clientHeight 
    }

    onResize = () => {
        this.initGeometry()
        this.setViewFactor(this.viewFactor)
        this.draw()
    }

    startBoxZoom(x: number, y: number) {
        this.boxZoomActive = true
        this.boxZoomX1 = x
        this.boxZoomY1 = y
        this.boxZoomX2 = x
        this.boxZoomY2 = y
        this.draw()

    }
    updateBoxZoom(x: number, y: number) {
        this.boxZoomX2 = x
        this.boxZoomY2 = y
        this.draw()

    }
    stopBoxZoom() {
        this.boxZoomActive = false
        this.zoomBox()
        this.draw()
    }


    draw() {
        assert(this.canvasRef)
        assert(this.props.data.keypoints)
        assert(this.props.data.exam)


        const ctx = this.canvasRef.getContext("2d")
        if (ctx) {
            ctx.fillStyle = "white"
            ctx.beginPath();
            ctx.rect(0, 0, this.canvasRef.width, this.canvasRef.height);
            ctx.fill()

            const sWidth = this.props.image.width - this.viewX
            const sHeight = this.props.image.height - this.viewY

            ctx.drawImage(this.props.image, this.viewX, this.viewY, sWidth, sHeight, 0, 0, sWidth * this.viewFactor, sHeight * this.viewFactor)
            drawSkeleton(ctx, this.props.data.exam, blueColorScheme, true, -this.viewX, -this.viewY, this.viewFactor)
            drawSkeleton(ctx, this.props.data.keypoints, this.colorScheme, true, -this.viewX, -this.viewY, this.viewFactor)



            // Drow zoom box
            if (this.boxZoomActive) {
                ctx.beginPath();
                ctx.rect(this.boxZoomX1, this.boxZoomY1, this.boxZoomX2 - this.boxZoomX1, this.boxZoomY2 - this.boxZoomY1);
                ctx.strokeStyle = "black";
                ctx.stroke();
            }
        }
    }


    async init() {
        assert(this.canvasRef)
        this.checkAnnotation()

        this.initGeometry()
        this.reset()
        this.initZoom()
        this.draw()
    }

    onClose = () =>{
        this.props.controller.ExamDialog=false; 
        if (this.metrics.pass) {
            this.props.annotator.real_next()
        }
    }

    componentWillUnmount() {
        if (this.canvasRef) {
            this.canvasRef.removeEventListener('mousedown', this.onMouseDown)
            this.canvasRef.removeEventListener('wheel', this.onWheel);
            this.canvasRef.removeEventListener('mouseup', this.onMouseUp)
            this.canvasRef.removeEventListener('mousemove', this.onMouseMove)
            this.canvasRef.removeEventListener('mouseout', this.onMouseLeave)

        }
        window.onresize = null
    }


    componentDidMount() {
        window.onresize = this.onResize;
        assert(this.canvasRef)   
        
        this.canvasRef.addEventListener('mousedown', this.onMouseDown)        
        this.canvasRef.addEventListener('wheel', this.onWheel);
        this.canvasRef.addEventListener('mouseup', this.onMouseUp)
        this.canvasRef.addEventListener('mousemove', this.onMouseMove)

        this.canvasRef.addEventListener('mouseout', this.onMouseLeave)


        this.canvasRef.oncontextmenu = function (e) {
            e.preventDefault();
        };
        this.init()
    }



    render() {
        return (
            <div ref={el => this.outer = el} style={{ width: "100%", height: "100%" }}>
                <canvas ref={el => this.canvasRef = el} />

                <div className="legend">
                    <table>
                        <tr><td colSpan={2}>Right Annotation</td></tr>
                        <tr><td colSpan={2}>  <LegendLine color="cyan"></LegendLine>   </td></tr>
                        <tr><td colSpan={2}>Your Annotation</td></tr>
                        <tr><td>Good</td><td><LegendLine color="green"></LegendLine></td></tr>
                        <tr><td>Avergae</td><td><LegendLine color="yellow"></LegendLine></td></tr>
                        <tr><td>Bad</td><td><LegendLine color="red"></LegendLine></td></tr>
                        <tr><td>IOU</td><td>{_.round(this.metrics.iou,1)}%</td></tr>
                        <tr><td>Average Error</td><td>{_.round(this.metrics.averageError,1)}cm</td></tr>
                        <tr><td>Max Error</td><td>{_.round(this.metrics.maxError,1)}cm</td></tr>
                        <tr><td>rmsd</td><td>{_.round(this.metrics.rmsd,1)}</td></tr>
                        <tr><td>status</td><td><b>{this.metrics.pass?"Passed":"Failed"}</b></td></tr>
                    </table>
                </div>
                <div className="buttons">
                    <button onClick={this.onClose}>OK</button>
                </div>
            </div>    
        )
    }

}


export default observer(ExamView);

