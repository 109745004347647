import {Keypoints, PointName } from "./keypoints"
//import assert from 'assert';
import _ from "lodash"

type NodeColor = string | string[]
type NodeColorMap = Map<PointName,NodeColor>
type PointPair = [PointName,PointName]
type RGB        = [number,number,number]
type PairColor = [PointPair,string]

function col (v:string|RGB) {
    if (typeof(v) === "string"){
        return  v
    } else {
        return `rgb(${v[0]},${v[1]},${v[2]})`
    }
}


export interface ColorScheme {
    pointColor(p:PointName):NodeColor;
    lines():PairColor[];
    lineWidth:number;
    radius:number;
    radius2:number;
    radius3:number;
}

export class XColorScheme implements ColorScheme { 
    nodeColor :  NodeColorMap =  new Map(); 
    lineColor :  PairColor[] = []
    radius2 = 0
    radius3 = 0
    constructor(nodeColor:NodeColorMap = new Map(), 
                lineColor:PairColor[]=[], 
                public defaultColor = "white", 
                public lineWidth = 5, 
                public radius = 5) {
        this.nodeColor = nodeColor
        this.lineColor = [
            [['neck', 'right_shoulder'], defaultColor],
            [['neck', 'left_shoulder'], defaultColor],
            [['neck_back', 'right_shoulder'], defaultColor],
            [['neck_back', 'left_shoulder'], defaultColor],
            [['right_shoulder', 'right_elbow'], defaultColor],
            [['right_elbow', 'right_wrist'], defaultColor],
            [['left_shoulder', 'left_elbow'], defaultColor],
            [['left_elbow', 'left_wrist'], defaultColor],
            [['right_hip', 'right_knee'], defaultColor],
            [['right_knee', 'right_ankle'], defaultColor],
            [['left_hip', 'left_knee'], defaultColor],
            [['left_knee', 'left_ankle'], defaultColor],
            [['neck', 'chin'], defaultColor],
            [['chin', 'nose'], defaultColor],
            [['right_eye', 'right_ear'], defaultColor],
            [['left_eye', 'left_ear'], defaultColor],
            [['left_wrist', "left_thumb"],defaultColor],
            [['left_wrist', "left_finger"],defaultColor],
            [['right_wrist', "right_thumb"],defaultColor],
            [['right_wrist', "right_finger"],defaultColor],
            [['right_ankle', "right_bigtoe"],defaultColor],
            [['right_ankle', "right_heel"],defaultColor],
            [['left_ankle', "left_bigtoe"],defaultColor],
            [['left_ankle', "left_heel"],defaultColor],
            [["neck_back","spinal_cord_1"],defaultColor],
            [["neck","spinal_cord_1"],defaultColor],
            [["spinal_cord_1","spinal_cord_2"],defaultColor],
            [["spinal_cord_2","spinal_cord_3"],defaultColor],
            [["spinal_cord_3","spinal_cord_4"],defaultColor],
            [["spinal_cord_4","spinal_cord_5"],defaultColor],
            [["left_hip","spinal_cord_5"],defaultColor],
            [["right_hip","spinal_cord_5"],defaultColor],


            [["neck_back","back_1"],defaultColor],
            [["back_1","back_2"],defaultColor],
            [["back_2","back_3"],defaultColor],
            [["back_3","back_4"],defaultColor],
            [["back_4","back_5"],defaultColor],
        
            [["neck","front_1"],defaultColor],
            [["front_1","front_2"],defaultColor],
            [["front_2","front_3"],defaultColor],
            [["front_3","front_4"],defaultColor],
            [["front_4","front_5"],defaultColor],
        
            [["left_hip","back_5"],defaultColor],
            [["right_hip","back_5"],defaultColor],
            [["left_hip","front_5"],defaultColor],
            [["right_hip","front_5"],defaultColor],
        ]
    }

    pointColor(p:PointName) {
        return this.nodeColor.get(p) || this.defaultColor
    }
    
    lines(){
        return this.lineColor
    }
    
}

export const whiteColorScheme  = new XColorScheme()
export const greenColorScheme  = new XColorScheme(undefined,undefined,"green", 3)
export const blueColorScheme   = new XColorScheme(undefined,undefined,"cyan")

export class AnnColorScheme implements ColorScheme {
    nodeColor :  NodeColorMap; 
    defaultColor : string = col([10,10,10])
    lineColor :  PairColor[] = []
    radius2 = 8
    radius1 = 4
    radius3 = 16

    constructor(public lineWidth = 5, public radius = 4){
        const left   = col([0, 255, 0])
        const right  = col([0, 0, 255])
        const center = col([255,0,0])
        const heel   = col([255,120,120])
        const thumb  = col([255, 255, 0])
        const back   = col([75,0,130])
        const front  = col([70,130,180])
        const black  = col([10,10,10])
        const gold  = col([255, 215, 0])
        const pink = col([255, 53, 184])
        const elbow = col([255,0,   0])
        const wrist = col([255,255, 0])
        const internal  = col([0,0,0])
        const external  = col([255,255,255])
        const knee = col([0,255,255])
        const ankle = col([255,0,255])
        const cyan = col([0,255,255])


        const thumb_color = col([255,0,0])
        const forefinger_color = col([155,255,255])
        const middle_finger_color = col([0,0,255])
        const ring_finger_color = col([255,255,100])
        const pinky_finger_color = col([255,128,0])
    

        this.nodeColor =  new Map<PointName,NodeColor>([
            ['nose',  center],               
            ['neck',  center],
            ["top_of_the_head", col([255, 85, 0])],
            ["chin", center],
            ['left_elbow',    left], ['right_elbow', right], 
            ['left_wrist',    left], ['right_wrist', right],
            ['left_shoulder', left], ['right_shoulder', right],
            ['left_hip', left],      ['right_hip', right],
            ['left_knee',left],      ['right_knee',  right],
            ['left_ankle', left] ,   ['right_ankle', right],
            ['left_eye', left],      ['right_eye',  right],
            ['left_ear', left],      ['right_ear', right],
            ["left_thumb",  left],   ["right_thumb",  right],
            ["left_finger", left],   ["right_finger", right],
              
            ["left_bigtoe", col([ 85, 0,255])],  
            ["right_bigtoe",col([ 85, 0,255])],
            ["left_heel",heel],     ["right_heel",heel],
            ["spinal_cord_1", center],
            ["spinal_cord_2", center],
            ["spinal_cord_3", center],
            ["spinal_cord_4", center],
            ["spinal_cord_5", center],
            ["neck_back", col([200,128,128])],
            ["back_1", back],
            ["back_2", back],
            ["back_3", back],
            ["back_4", back],
            ["back_5", back],

            ["front_1", front],
            ["front_2", front],
            ["front_3", front],
            ["front_4", front],
            ["front_5", front],

            ["right_thumb1", right],
            ["right_thumb2", right],
            ["right_thumb3", right],
            ["right_thumb4", right],
          
            ["right_forefinger1", right],
            ["right_forefinger2", right],
            ["right_forefinger3", right],
            ["right_forefinger4", right],
            ["right_middle_finger1", right],
            
            ["right_middle_finger2", right],
            ["right_middle_finger3", right],
            ["right_middle_finger4", right],
            
            ["right_ring_finger1", right],
            ["right_ring_finger2", right],
            ["right_ring_finger3", right],
            ["right_ring_finger4", right],
           
            ["right_pinky_finger1", right],
            ["right_pinky_finger2", right],
            ["right_pinky_finger3", right],
            ["right_pinky_finger4", right],
          

            ["left_thumb1", left],
            ["left_thumb2", left],
            ["left_thumb3", left],
            ["left_thumb4", left],
          
            ["left_forefinger1", left],
            ["left_forefinger2", left],
            ["left_forefinger3", left],
            ["left_forefinger4", left],
            ["left_middle_finger1", left],
            
            ["left_middle_finger2", left],
            ["left_middle_finger3", left],
            ["left_middle_finger4", left],
            
            ["left_ring_finger1", left],
            ["left_ring_finger2", left],
            ["left_ring_finger3", left],
            ["left_ring_finger4", left],
           
            ["left_pinky_finger1", left],
            ["left_pinky_finger2", left],
            ["left_pinky_finger3", left],
            ["left_pinky_finger4", left],

            ["left_shoulder_hand", left],
            ["right_shoulder_hand", right], 
            ["left_shoulder_neck", left],
            ["right_shoulder_neck", right],
            ["left_under_ear", left],
            ["right_under_ear", right], 
            ["neck_top", center], 
            ["back_neck_top",col([200,128,128])],

            ["RWP",  [right , wrist]],
            ["LWP" , [left  , wrist] ],

            ["RWT", [right,  wrist]],
            ["LWT", [left,   wrist]],

            ["RWI", [right , wrist]],
            ["LWI", [left,   wrist]],

            ["RWE", [right,  wrist]],
            ["LWE", [left,   wrist]],   

            ["REI", [right,  elbow]],
            ["LEI", [left,   elbow] ],
            ["REE", [right,  elbow]], 
            ["LEE", [left,   elbow]],


            ["RKI",[right,  knee]], 
            ["LKI",[left,   knee]],
            ["RKF",[right,  knee]],
            ["LKF",[left,  knee]],
            ["RKE",[right,  knee]],
            ["LKE",[left,   knee]],
            ["RKB",[right,  knee]],
            ["LKB",[left,   knee]],

            ["RAI",[right,  ankle]],
            ["LAI",[left,   ankle]],
            ["RAF",[right,  ankle]],
            ["LAF",[left,   ankle]],
            ["RAE",[right,  ankle]],
            ["LAE",[left,   ankle]],
            ["RAB",[right,  ankle]],
            ["LAB",[left,   ankle]],

            ["left_smalltoe",cyan],
            ["right_smalltoe",cyan],

        ])

        this.lineColor = [
            [['neck', 'right_shoulder'], right],
            [['neck', 'left_shoulder'], left],
            [['neck_back', 'right_shoulder'], right],
            [['neck_back', 'left_shoulder'], left],

            [['right_shoulder', 'right_elbow'], right],
            [['right_elbow', 'right_wrist'], right],
            [['left_shoulder', 'left_elbow'], left],
            [['left_elbow', 'left_wrist'], left],
            [['right_hip', 'right_knee'], right],
            [['right_knee', 'right_ankle'], right],
            [['left_hip', 'left_knee'], left],
            [['left_knee', 'left_ankle'], left],

            //[['neck', 'chin'], center],
            [['neck_top', 'chin'], center],

            [['chin', 'nose'], center],
            //[['nose', 'right_eye'], right],
            [['right_eye', 'right_ear'], right],
            //[['nose', 'left_eye'], left],
            [['left_eye', 'left_ear'], left],
        
            [['left_wrist', "left_thumb"],thumb],
            [['left_wrist', "left_finger"],left],
            [['right_wrist', "right_thumb"],thumb],
            [['right_wrist', "right_finger"],right],
        
            [['right_ankle', "right_bigtoe"],col([ 85, 0,255])],
            [['right_ankle', "right_smalltoe"],col([ 85, 0,255])],
            [['right_bigtoe', "right_smalltoe"],col([ 85, 0,255])],
            [['right_ankle', "right_heel"],heel],

            [['left_ankle', "left_bigtoe"],col([ 85, 0,255])],
            [['left_ankle', "left_smalltoe"],col([ 85, 0,255])],
            [['left_bigtoe', "left_smalltoe"],col([ 85, 0,255])],


            [['left_ankle', "left_heel"],heel],
        
            [["neck_back","spinal_cord_1"],center],
            [["neck","spinal_cord_1"],center],
            [["spinal_cord_1","spinal_cord_2"],center],
            [["spinal_cord_2","spinal_cord_3"],center],
            [["spinal_cord_3","spinal_cord_4"],center],
            [["spinal_cord_4","spinal_cord_5"],center],
        
            [["neck_back","back_neck_top"],back],
            [["neck_back","back_1"],back],
            [["back_1","back_2"],back],
            [["back_2","back_3"],back],
            [["back_3","back_4"],back],
            [["back_4","back_5"],back],
        
            [["neck","neck_top"],front],
            [["neck","front_1"],front],
            [["front_1","front_2"],front],
            [["front_2","front_3"],front],
            [["front_3","front_4"],front],
            [["front_4","front_5"],front],
    

            [["left_hip","spinal_cord_5"],left],
            [["right_hip","spinal_cord_5"],right],

            [["left_hip","back_5"],left],
            [["right_hip","back_5"],right],
            [["left_hip","front_5"],left],
            [["right_hip","front_5"],right],


            [["left_shoulder_neck","left_shoulder_hand"], gold],
            [["right_shoulder_neck","right_shoulder_hand"], gold],
            [["right_under_ear","right_shoulder_neck"], gold],
            [["left_under_ear","left_shoulder_neck"], gold],
        

            [["left_wrist", "left_thumb1"]  , thumb_color], 
            [["left_thumb1", "left_thumb2"] , thumb_color], 
            [["left_thumb2", "left_thumb3"] , thumb_color], 
            [["left_thumb3", "left_thumb4"] , thumb_color], 

            [["left_wrist", "left_forefinger1"]  , forefinger_color], 
            [["left_forefinger1", "left_forefinger2"] , forefinger_color], 
            [["left_forefinger2", "left_forefinger3"] , forefinger_color], 
            [["left_forefinger3", "left_forefinger4"] , forefinger_color], 
    
            [["left_wrist", "left_middle_finger1"]  , middle_finger_color], 
            [["left_middle_finger1", "left_middle_finger2"] , middle_finger_color], 
            [["left_middle_finger2", "left_middle_finger3"] , middle_finger_color], 
            [["left_middle_finger3", "left_middle_finger4"] , middle_finger_color], 
    
            [["left_wrist", "left_ring_finger1"]  , ring_finger_color], 
            [["left_ring_finger1", "left_ring_finger2"] , ring_finger_color], 
            [["left_ring_finger2", "left_ring_finger3"] , ring_finger_color], 
            [["left_ring_finger3", "left_ring_finger4"] , ring_finger_color], 
    
            [["left_wrist", "left_pinky_finger1"]  , pinky_finger_color], 
            [["left_pinky_finger1", "left_pinky_finger2"] , pinky_finger_color], 
            [["left_pinky_finger2", "left_pinky_finger3"] , pinky_finger_color], 
            [["left_pinky_finger3", "left_pinky_finger4"] , pinky_finger_color], 
    
    
            [["right_wrist", "right_thumb1"]  , thumb_color], 
            [["right_thumb1", "right_thumb2"] , thumb_color], 
            [["right_thumb2", "right_thumb3"] , thumb_color], 
            [["right_thumb3", "right_thumb4"] , thumb_color], 
    
            [["right_wrist", "right_forefinger1"]  , forefinger_color], 
            [["right_forefinger1", "right_forefinger2"] , forefinger_color], 
            [["right_forefinger2", "right_forefinger3"] , forefinger_color], 
            [["right_forefinger3", "right_forefinger4"] , forefinger_color], 
    
            [["right_wrist", "right_middle_finger1"]  , middle_finger_color], 
            [["right_middle_finger1", "right_middle_finger2"] , middle_finger_color], 
            [["right_middle_finger2", "right_middle_finger3"] , middle_finger_color], 
            [["right_middle_finger3", "right_middle_finger4"] , middle_finger_color], 
    
            [["right_wrist", "right_ring_finger1"]  , ring_finger_color], 
            [["right_ring_finger1", "right_ring_finger2"] , ring_finger_color], 
            [["right_ring_finger2", "right_ring_finger3"] , ring_finger_color], 
            [["right_ring_finger3", "right_ring_finger4"] , ring_finger_color], 
    
            [["right_wrist", "right_pinky_finger1"]  , pinky_finger_color], 
            [["right_pinky_finger1", "right_pinky_finger2"] , pinky_finger_color], 
            [["right_pinky_finger2", "right_pinky_finger3"] , pinky_finger_color], 
            [["right_pinky_finger3", "right_pinky_finger4"] , pinky_finger_color], 
    
    

        ]
    }

    pointColor(p:PointName) {
        return this.nodeColor.get(p) || this.defaultColor
    }
    
    lines(){
        return this.lineColor
    }
    

}

export const annColorScheme = new AnnColorScheme()


export function drawSkeleton(ctx:CanvasRenderingContext2D, kp:Keypoints, col : ColorScheme, lines=true, dx=0, dy=0, f=1, 
                             subset:PointName[]|null=null)
{
    //console.log(subset,kp)
    if (kp == null) {
        return
    }

    ctx.lineWidth = col.lineWidth;

    for (let point of kp.points()) {  
        //console.log("*",point)

        if (subset && _.indexOf(subset,point) < 0) {
            continue
        }
        //console.log("***",point)
        let p = kp.get(point)!
        let {x,y} = p.scale(dx, dy, f)
        ctx.beginPath();
        ctx.arc(x, y, col.radius , 0, 2 * Math.PI, false);

        const pointColors = col.pointColor(point);
        if (Array.isArray(pointColors)) {

            if (pointColors.length>2){
                ctx.beginPath();
                ctx.arc(x, y, col.radius3 , 0, 2 * Math.PI, false);    
                ctx.fillStyle = pointColors[2]
                ctx.fill();    
            }
            if (pointColors.length>1){
                ctx.beginPath();
                ctx.arc(x, y, col.radius2 , 0, 2 * Math.PI, false);    
                ctx.fillStyle = pointColors[1]
                ctx.fill();    
            }
            ctx.beginPath();
            ctx.arc(x, y, col.radius , 0, 2 * Math.PI, false);    
            ctx.fillStyle = pointColors[0]
            ctx.fill();


        } else {
            ctx.beginPath();
            ctx.arc(x, y, col.radius , 0, 2 * Math.PI, false);    
            ctx.fillStyle = pointColors
            ctx.fill();
        }
    }
    if (!lines) {
        return
    }
    for (let [pair,c] of col.lines()) {
        let [p1,p2] = pair
        let pp1 = kp.get(p1)
        let pp2 = kp.get(p2)
        if (pp1 && pp2) {             
            if (subset) {
                if (_.indexOf(subset,p1) < 0) {
                    continue
                }
                if (_.indexOf(subset,p2) < 0) {
                    continue
                }
            }
        

            let {x:x1,y:y1} = pp1.scale(dx, dy, f)
            let {x:x2,y:y2} = pp2.scale(dx, dy, f)

            ctx.beginPath();
            ctx.strokeStyle = c;  
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2,y2);
            ctx.stroke(); 
        }
    }
    
}



export default drawSkeleton
