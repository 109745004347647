import React, { Component } from 'react';
import { observer } from "mobx-react";
import manager from '../../Manager';

import {FiEdit} from 'react-icons/fi'
import {AiOutlineUser} from 'react-icons/ai'
import {GrLogout} from 'react-icons/gr'
import { NavLink } from 'react-router-dom';
import {Tooltip} from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'

type InfoPanelProps = {
}


class InfoPanel extends Component<InfoPanelProps> {
    render() {
    console.log("InfoPanel:", manager.stats?.annotated_today)
        return <div className="infoPanel"> 
                {manager.isAdmin  &&   <NavLink to="/admin" data-tooltip-id="InfoPanel-tooltip" data-tooltip-content="admin"><AiOutlineUser data-tip="admin"/></NavLink> }
                 {manager.user?.first_name} {manager.user?.last_name}  &nbsp; 
                 {manager.user?.ver} &nbsp; 
                <FiEdit/> {manager.stats?.annotated_today} today | {manager.stats?.annotated_total}total   &nbsp;&nbsp; 
                <GrLogout onClick={manager.logout} data-tooltip-id="InfoPanel-tooltip" data-tooltip-content="logout"/>
                <Tooltip id="InfoPanel-tooltip"/>
            </div>
    }
}
export default observer(InfoPanel);

