function sum(arr:number[]){
    return arr.reduce(function(a,b){
      return a + b
    }, 0);
}
function avg(arr:number[]){
    return sum(arr)/arr.length
}

function round2(x:number){
    return Math.round(x*100)/100
}

export class Point {
    x : number = -100;
    y : number = -100;

    constructor(x:number,y:number){
      this.x = x
      this.y = y
    }

    clone() : Point {
      return new Point(this.x,this.y)
  }

    get ix() : number {
      return Math.round(this.x)
    }

    get iy() : number {
      return Math.round(this.y)
    }

    scale(dx=0,dy=0,f=1){
      return new Point((this.x+dx)*f, (this.y+dy)*f )
    }

    dist(other:Point):number {
      const dx = other.x - this.x 
      const dy = other.y - this.y 
      return Math.sqrt(dx*dx+dy*dy)
    }


    /*
    toString() : string {
      return `[${this.ix},${this.iy}]`
    }

    public toJSON() {
      return [this.ix,this.iy]
    }
    */
}

class _Keypoints {
  nose? : Point;
  top_of_the_head? : Point;
  chin? : Point;
  neck? : Point;
  spinal_cord_1? : Point;
  spinal_cord_2? : Point;
  spinal_cord_3? : Point;
  spinal_cord_4? : Point;
  spinal_cord_5? : Point;
  left_eye? : Point;
  right_eye? : Point;
  left_ear? : Point;
  right_ear? : Point;
  left_shoulder? : Point;
  right_shoulder? : Point;
  left_elbow? : Point;
  right_elbow? : Point;
  left_wrist? : Point;
  right_wrist? : Point;
  left_hip? : Point;
  right_hip? : Point;
  left_knee? : Point;
  right_knee? : Point;
  left_ankle? : Point;
  right_ankle? : Point;
  left_bigtoe? : Point;
  right_bigtoe? : Point;
  left_heel? : Point;
  right_heel? : Point;
  left_finger? : Point;
  right_finger? : Point;
  left_thumb? : Point;
  right_thumb? : Point;
  neck_back? : Point;
  back_1? : Point;
  back_2? : Point;
  back_3? : Point;
  back_4? : Point;
  back_5? : Point;
  front_1? : Point;
  front_2? : Point;
  front_3? : Point;
  front_4? : Point;
  front_5? : Point;

  left_shoulder_hand? : Point;
  right_shoulder_hand? : Point;
  left_shoulder_neck? : Point;
  right_shoulder_neck? : Point;
  left_under_ear? : Point;
  right_under_ear? : Point;
  neck_top? : Point;
  back_neck_top? : Point;

  RWT? : Point;
  LWT? : Point;
  RWP? : Point;
  LWP? : Point;
  RWI? : Point;
  LWI? : Point;
  RWE? : Point;
  LWE? : Point;
  RKI? : Point;
  LKI? : Point;
  RKF? : Point;
  LKF? : Point;
  RKE? : Point;
  LKE? : Point;
  RKB? : Point;
  LKB? : Point;
  REI? : Point;
  LEI? : Point;
  REE? : Point;
  LEE? : Point;
  RAI? : Point;
  LAI? : Point;
  RAF? : Point;
  LAF? : Point;
  RAE? : Point;
  LAE? : Point;
  RAB? : Point;
  LAB? : Point;

  left_thumb1? : Point;
  left_thumb2? : Point;
  left_thumb3? : Point;
  left_thumb4? : Point;
 
  left_forefinger1? : Point;
  left_forefinger2? : Point;
  left_forefinger3? : Point;
  left_forefinger4? : Point;
  
  left_middle_finger1? : Point;
  left_middle_finger2? : Point;
  left_middle_finger3? : Point;
  left_middle_finger4? : Point;
  
  left_ring_finger1? : Point;
  left_ring_finger2? : Point;
  left_ring_finger3? : Point;
  left_ring_finger4? : Point;
 
  left_pinky_finger1? : Point;
  left_pinky_finger2? : Point;
  left_pinky_finger3? : Point;
  left_pinky_finger4? : Point;
  
  
  right_thumb1? : Point;
  right_thumb2? : Point;
  right_thumb3? : Point;
  right_thumb4? : Point;
  right_forefinger1? : Point;
  
  right_forefinger2? : Point;
  right_forefinger3? : Point;
  right_forefinger4? : Point;
  right_middle_finger1? : Point;
  
  right_middle_finger2? : Point;
  right_middle_finger3? : Point;
  right_middle_finger4? : Point;
  
  right_ring_finger1? : Point;
  right_ring_finger2? : Point;
  right_ring_finger3? : Point;
  right_ring_finger4? : Point;
 
  right_pinky_finger1? : Point;
  right_pinky_finger2? : Point;
  right_pinky_finger3? : Point;
  right_pinky_finger4? : Point;

  left_smalltoe?:Point;
  right_smalltoe?:Point;
  
};

export type PointName = keyof _Keypoints;


export const AllModelPoints:PointName[] = ['nose', "top_of_the_head", "chin","neck",
              "spinal_cord_1", "spinal_cord_2", "spinal_cord_3", "spinal_cord_4", "spinal_cord_5",
              'left_eye', 'right_eye',  "left_ear", "right_ear",
              'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
              'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
              "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
              "left_finger","right_finger", "left_thumb", "right_thumb"  , "neck_back",
              "back_1","back_2","back_3","back_4","back_5",
              "front_1","front_2","front_3","front_4","front_5",
              "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
              "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
        "RWT",
        "LWT",
        "RWP",
        "LWP",
        "RWI",
        "LWI",
        "RWE",
        "LWE",

        "RKI",
        "LKI",
        "RKF",
        "LKF",
        "RKE",
        "LKE",
        "RKB",
        "LKB",

        "REI",
        "LEI",
        "REE",
        "LEE",
        
        "RAI",
        "LAI",
        "RAF",
        "LAF",
        "RAE",
        "LAE",
        "RAB",
        "LAB",

        'left_thumb1', 'left_thumb2', 'left_thumb3', 'left_thumb4',
        'left_forefinger1', 'left_forefinger2', 'left_forefinger3', 'left_forefinger4', 
        'left_middle_finger1', 'left_middle_finger2', 'left_middle_finger3', 'left_middle_finger4', 
        'left_ring_finger1', 'left_ring_finger2', 'left_ring_finger3', 'left_ring_finger4',
        'left_pinky_finger1', 'left_pinky_finger2', 'left_pinky_finger3', 'left_pinky_finger4', 

        'right_thumb1', 'right_thumb2', 'right_thumb3', 'right_thumb4', 'right_forefinger1', 
        'right_forefinger2', 'right_forefinger3', 'right_forefinger4', 'right_middle_finger1', 
        'right_middle_finger2', 'right_middle_finger3', 'right_middle_finger4', 
        'right_ring_finger1', 'right_ring_finger2', 'right_ring_finger3', 'right_ring_finger4',
        'right_pinky_finger1', 'right_pinky_finger2', 'right_pinky_finger3', 'right_pinky_finger4',
        
        "left_smalltoe", "right_smalltoe",
]



export class BBox {
  constructor (public x1:number,public y1:number,public x2:number,public y2:number) {
  }
  toString(){
    return `(${Math.round(this.x1)},${Math.round(this.y1)})-(${Math.round(this.x2)},${Math.round(this.y2)})`
  }

  get height(){
    return this.y2 - this.y1
  }
  get width(){
    return this.x2 - this.x1
  }

  get iHeight(){
    return Math.floor(this.y2 - this.y1)
  }
  get iWidth(){
    return Math.floor(this.x2 - this.x1)
  }
  get ix1(){
    return Math.floor(this.x1)
  }
  get ix2(){
    return Math.floor(this.x2)
  }
  get iy1(){
    return Math.floor(this.y1)
  }
  get iy2(){
    return Math.floor(this.y2)
  }



  center():[number,number]{
    const cx = (this.x2 + this.x1)/2
    const cy = (this.y2 + this.y1)/2
    return [cx,cy]
  }

  square():BBox {
    const [cx,cy] = this.center()
    const sx = this.x2 - cx
    const sy = this.y2 - cy
    const s = Math.max(sx,sy)!
    return new BBox(cx-s,cy-s,cx+s,cy+s)
  }

  enlarge(factor:number){
    const [cx,cy] = this.center()
    const sx = this.x2 - cx
    const sy = this.y2 - cy
    return new BBox(cx-sx*factor,cy-sy*factor,cx+sx*factor,cy+sy*factor)
  }

  overlap(x1:number, y1:number, x2:number, y2:number){
      return new BBox(
        Math.max(this.x1,x1)!,
        Math.max(this.y1,y1)!,
        Math.min(this.x2,x2)!,
        Math.min(this.y2,y2)!
      )
  }

}

export class Keypoints extends _Keypoints {

  set = (k:PointName, v:Point) => {
      this[k] = v;
  }

  get = (k:PointName) : Point|undefined => {
      return this[k]
  }

  hasPoint = (k:PointName) : boolean => {
    return this[k] !== undefined && this[k]!.y > -100 && this[k]!.x > -100
  }

  hasPoints = (points:PointName[]) : boolean => {
    return points.every(k=>this.hasPoint(k))
  }


  countPresentPoints = (points:PointName[]) : number => {
    let count = 0
    for (let p of points) {
      if (this.hasPoint(p)) {
        count ++
      }
    }
    return count;
  }
  
  hasSomePoints = (points: PointName[]): boolean => {
    return points.some(p => this.hasPoint(p));
  }

  present = (points: PointName[] = AllModelPoints): PointName[] => {
    return points.filter(p => this.hasPoint(p));
  }

  absent = (points: PointName[] = AllModelPoints): PointName[] => {
    return points.filter(p => !this.hasPoint(p));
  }


  points = ():PointName[] => {
    return AllModelPoints.filter(p=>this.hasPoint(p))
  }
  _getX(p:PointName) : number{
    return this[p]!.x
  }
  _getY(p:PointName) : number{
    return this[p]!.y
  }

  _distX(p1:PointName,p2:PointName) : number{
    return this[p1]!.x - this[p2]!.x
  }
  _distY(p1:PointName,p2:PointName) : number{
    return this[p1]!.y - this[p2]!.y
  }

  distX(p1:PointName,p2:PointName) : number | null{
    if (this.hasPoints([p1,p2])) {
      return this[p1]!.x - this[p2]!.x
    } else {
      return null
    }
  }

  dist(p1:PointName,p2:PointName) : number | null{
    if (this.hasPoints([p1,p2])) {
      const dx = this._distX(p1,p2)
      const dy = this._distY(p1,p2)
      return Math.sqrt(dx*dx+dy*dy)
    } else {
      return null
    }
  }

  nearestPoint(x:number,y:number, minDist:number = 20):PointName|null {
      let  minDist2 = minDist * minDist
      let  closest : PointName|null = null;
      for(let p of this.points()) {
        const pp = this.get(p)!
        const {x:xp,y:yp} = pp
        const d2 = (xp-x)**2 + (yp-y)**2  
        if (d2 < minDist2) {
          minDist2 = d2
          closest = p
        }
      }
      return closest
  }

  calcAngle(joints: [PointName,PointName], flipX:boolean=false): number|null {
    if (!this.hasPoints(joints)){
      return null;
    }
    if (flipX) {
        return Math.atan2(this._distY(...joints), this._distX(...joints)) * 180 / Math.PI;
    } else {
        return Math.atan2(this._distY(...joints), - this._distX(...joints)) * 180 / Math.PI;
    }
  }

  calcAngle3(joints: [PointName,PointName,PointName]): number|null {
    if (!this.hasPoints(joints)){
      return null;
    }
    let a1 = this.calcAngle([joints[0],joints[1]]);
    let a2 = this.calcAngle([joints[1],joints[2]]);
    if (a1 == null || a2 == null) return null;
    let val = a1-a2;  
    if(val > 180) val -= 180;
    if(val < -180) val += 180;
    return val;
  }

  averageHeight( points:PointName[]):number|null {
    const presentPoints = points.filter( p=> this.hasPoint(p))
    if (presentPoints.length === 0){
      return null
    }
    const ys = presentPoints.map(p=>this.get(p)!.y)
    return avg(ys)
  }

  angle(joints: [PointName,PointName]): number {
    if (!this.hasPoints(joints)){
      return NaN
    }
    return Math.atan2(this._distY(...joints), - this._distX(...joints)) * 180 / Math.PI;
  }

  angle3(joints: [PointName,PointName,PointName]): number {
    if (!this.hasPoints(joints)){
      return NaN
    }
    const [A,B,C] = joints
    return 180 - this.angle([A,B]) + this.angle([B,C]) 
  }


  isProfile = () => {
    const spineLength = this.dist('spinal_cord_5', 'spinal_cord_3');
    const hipLength = this.dist("right_hip", "left_hip");

    if (spineLength && hipLength) {
      if (hipLength / spineLength >= 0.6) {
        return false;
      }
    }

    return true;
  }


  translate(x:number,y:number) {
    const pp = this.points();
    for(let p of pp) {
        this[p]!.x += x
        this[p]!.y += y      
    }
  }
  factor(f:number) {
    const pp = this.points();
    for(let p of pp) {
        this[p]!.x *= f
        this[p]!.y *= f
    }
  }


  bbox(minPoints=6):BBox|null{
    const pp = this.points();
    if (pp.length<minPoints) {
      return null;
    }

    const xs = pp.map(p=>this._getX(p));
    const x2 = Math.max(...xs);
    const x1 = Math.min(...xs);

    const ys = pp.map(p=>this._getY(p));
    const y2 = Math.max(...ys);
    const y1 = Math.min(...ys);
    return new BBox(x1!,y1!,x2!,y2!);
  }

  asMap(){
    let m = new Map<string, Point>();
    let p:PointName;
    for (p of this.points()) {
      m.set(p,this[p]!)
    }
    return m
  }

  static fromMap(m : Map<string, Point>|null):Keypoints {
    let k = new Keypoints();
    if (!m) {
      return k
    }
    for(let p of AllModelPoints) {
      let v =  m.get(p)
      if (v) {
        k[p] = v
      }
    }
    return k
  }
 
  toString() : string{
    let res = ""
    for (let p of this.points()) {
      res += `${p}:${this[p]} `
    }
    return res;
  }

  public toJSON(){
    const res : any = {}
    for (let p of this.points()) {
      const pp  = this[p]
      if (pp) {
        res[p] = [ round2(pp.x), round2(pp.y)]
      }
    }
    return res
  }

  static fromJSON(m : any ):Keypoints {
    let k = new Keypoints();
    if (!m) {
      return k
    }
    for(let p of AllModelPoints) {
      let v =  m[p]
      if (v) {
        k[p] = new Point(v[0],v[1])
      }
    }
    return k
  }

  subset(points:PointName[]){
    const res = new Keypoints()
    for(let p of points) {
        const v = this.get(p)
        if (v) {
            res.set(p,v)
        }
    }
    return res
  }
}


export function fromMap(m : Map<string, Point>):Keypoints {
  let k = new Keypoints();
  if (!m) {
    return k
  }
  for(let p of AllModelPoints) {
    let v =  m.get(p)
    if (v) {
      k[p] = v
    }
  }
  return k
}
