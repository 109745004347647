import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable,toJS } from "mobx";
import { PEAnnData } from '../../../api_types';
import backend from '../../../backend';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { difficultyOptions, equipmentOptions, exerciseGoalOptions, jointOptions, movementOptions, muscleOptions } from './options';

/*
//this.controller = new Controller(window.location.search)
class Controller {
    constructor(query? : string) {
        console.log("Controller:",query)
    }
}
*/

type MySelectProps = {
  field : string
  title : string
  options : string[]
  annotation: any
}


const AutocompleteMenu = observer((props : MySelectProps) => {
    return (
    <Autocomplete
        autoHighlight
        className='pe-input'
        options={props.options}
        sx={{ width: 600 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}
        onChange={(event, values) => {
            props.annotation[props.field] = values
        }} 
        value={props.annotation[props.field]}
  />)
  })

  const AutocompleteMenuMulti = observer((props : MySelectProps) => {
    return (
    <Autocomplete
        value={props.annotation[props.field]}
        disableCloseOnSelect autoHighlight multiple
        className='pe-input'
        options={props.options}
        sx={{ width: 600 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}

        onChange={(event, values) => {
            //console.log("====================================", values)    
            props.annotation[props.field] = values
        }} 
  />)
  })
  
/*

const MultiSelect = observer((props : MySelectProps) => {
    return (<>

        <label>{props.title}: 
       <Select className='pe-input w500'
          multiple
          value={toJS(props.annotation[props.field])}
          onChange={(event: SelectChangeEvent<string>) => {
                     const value = event.target.value
                     props.annotation[props.field] = typeof value === 'string' ? value.split(',') : value
            }} 
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected:any) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {props.options.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={props.annotation[props.field].indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
       </label><br/> 
       </>)
})

*/

class PEAnnotator extends Component<{}> {
    peid : number = 0
    data : PEAnnData|null = null
    url : string = ""
    video : HTMLVideoElement | null = null
    videoSpeed = 2.0


    query : string
    qcmode : boolean = false
    current : number = 0
    todo : any[]|null = null

    annotation: any  = {
        movement : "unselected",
        difficulty : "unselected",
        equipment : []
    }

    constructor(props:{}) {
        super(props) 
        makeObservable(this, {
            url : observable,
            videoSpeed: observable,      
            annotation: observable,  
        });
        this.query = window.location.search
        console.log("query:>>>>>>>>>>>>>",this.query)
        if (this.query) {
            this.qcmode = true
        }
    }


    setSpeed(x:number) {
        this.videoSpeed = x
        if (this.video) {
            this.video.playbackRate = x 
        }
    }

    get videurl() : string{
        return this.url
    }

    
    onChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>)=>{
        this.annotation[e.target.id] = e.target.value
    }

    onCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        this.annotation[e.target.id] = e.target.checked
    }


    save = async () => {
        await backend.peann_save(this.peid, toJS(this.annotation))
        this.load()
    }
    
    async load() {
        if (this.qcmode) {
            this.loadQC()
        } else {
            this.loadAnn()
        }
    }

    async getQcData() {
        if (!this.todo) {
            this.todo = await backend.peann_qcdata(this.query)
            //console.log("todo:",this.todo)
            this.current = 0
        }
        if (this.current<0 || !this.todo || this.current >= this.todo.length) {
            window.location.href = "/pea/admin"
            return null
        }
        return this.todo![this.current]
    }

    next = () => {
        this.current++
        this.loadQC()
    }
    prev = () => {
        this.current--
        this.loadQC()
    }


    async loadQC() {
        const data : any = await this.getQcData()
        if (data) {
            //console.log("loadQC::::::::::::::",data)
            this.annotation = data.ann
            this.peid = data.peid
            //this.peaid = data.peaid
            this.data = data.data
            this.url = `https://app.kemtai.com/media/exercises/${this.data!.uuid}/thumbnail-loop.webm`
            this.setSpeed(this.videoSpeed)
        }
    }

    async loadAnn() {
        const data : any = await backend.peann_todo()

        if (data) {
            this.peid = data.peid
            this.data = data.data

            this.annotation = {
                equipment : data.data.eq,
                movement  : "",
                difficulty : "",
                exerciseGoal : "",
                joint:  "",
                main_muscle : "",
                balance : false,
                passive : false,
                non_weight_bearing : false,
                other_muscles : [],
            }

           //console.log("load:",this.data)
            this.url = `https://app.kemtai.com/media/exercises/${this.data!.uuid}/thumbnail-loop.webm`
            this.setSpeed(this.videoSpeed)
        }
    }

    componentDidMount() {
        this.load()
    }


    render() {
        const videurl = this.videurl;

        if (!this.data) {
            return null
        }
        
        return (
        <div key={this.data?.id}>
            <div style={{marginLeft:"70px"}}>
            <h3>{this.data?.name} ({this.data?.id})</h3>
            <video ref={ref=>this.video=ref} src={this.videurl} controls loop autoPlay 
                    style={{width:"500px"}}/>
            <div>
            <span style={{verticalAlign:"110%"}}>             
                            &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                            &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                    {[0.25,0.5,1,2,4].map(
                        v => <button onClick={e=>this.setSpeed(v)} key={v}
                                    style={ this.videoSpeed===v ? {color:"blue", fontWeight:"bold" }:{}}>
                            {v}x 
                            </button>
                    )}


                </span>
                </div> 
                </div>

        <div className='pe-form'>
             
       <AutocompleteMenu title='main muscle' field='main_muscle' options={muscleOptions} annotation={this.annotation}></AutocompleteMenu>       

       <AutocompleteMenuMulti title='other muscles' field='other_muscles' options={muscleOptions} annotation={this.annotation}></AutocompleteMenuMulti>       


       <TextField
            label="synonims" 
            multiline  rows={2} 
            value={this.annotation["synonims"]}
            className='pe-input'
            sx={{ width: 600 }}
            onChange={(event) => {
                this.annotation["synonims"] = event.target.value  
            }} 
        />


       <AutocompleteMenu title='exercise goal' field='exerciseGoal' options={exerciseGoalOptions} annotation={this.annotation}></AutocompleteMenu>       
       <AutocompleteMenu title='movement' field='movement' options={movementOptions} annotation={this.annotation}></AutocompleteMenu>
       <AutocompleteMenu title='joint' field='joint' options={jointOptions} annotation={this.annotation}></AutocompleteMenu>
       <AutocompleteMenu title='difficulty' field='difficulty' options={difficultyOptions} annotation={this.annotation}></AutocompleteMenu>
       <AutocompleteMenuMulti title='equipment' field='equipment' options={equipmentOptions} annotation={this.annotation}></AutocompleteMenuMulti>

       
       <br/>
        <FormControlLabel
             label="balance"
             control={ <Checkbox id="balance" onChange={this.onCheckboxChange} checked={this.annotation["balance"]}/>}/>
        <FormControlLabel
             label="passive"
             control={ <Checkbox id="passive" onChange={this.onCheckboxChange} checked={this.annotation["passive"]} />}/>
        <FormControlLabel
             label="non weight bearing"
             control={ <Checkbox id="non_weight_bearing" onChange={this.onCheckboxChange} checked={this.annotation["non_weight_bearing"]}  />}/>
        <br/>

           {!this.qcmode &&<Button variant="contained" size="large" onClick={this.save}>Save</Button>}
           {this.qcmode && <Button variant="contained" size="large" onClick={this.next}>Next</Button>}
           {this.qcmode && this.current>0 && <Button variant="contained" size="large" onClick={this.prev}>Prev</Button>}

    </div>                
    </div>
        )
    }

}
export default observer(PEAnnotator);
