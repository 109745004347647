import React from "react";
import { Component } from "react";
import { observer } from "mobx-react";
import backend from '../../../backend';
import { makeObservable, observable, toJS } from "mobx";



class FacesSummary extends Component {

    data : any = null

    constructor(props:any){
        super(props)

        makeObservable(this, {
            data: observable,
        });

    }

    componentDidMount() {
        this.load()
    }

    async load() {
        this.data = await backend.faces_summary()
        console.log("data",toJS(this.data))
    }


    render() {

        if (!this.data) {
            return null
        }
        return (
          <div style={{display: "flex", flexWrap: "wrap"}}>
            {this.data.map(
                (item:any) => {
                    return <div key={item.face_id}>
                                {item.face_id} {item.age} {item.gender}  <br/>
                               <a href={`/faces/details/?face_id=${item.face_id}`}>
                                  <img height="480px" width="360px" src={`https://annotator.kemtai.com/${item.path}`} />
                                </a>
                               
                               
                            </div>
                }
            )}
        </div>)
        

        /*
        return (<DataTable
            data={this.data}
            columns={[
                {name: 'ID',  cell: (row:any) => {
                    return <a href={`/faces/details/?face_id=${row.face_id}`}>{row.face_id}</a>
                }
                },
                {name: 'gender',  selector: 'gender'},
                {name: 'age',  selector: 'age'},
                {name: 'face',                  
                cell: (row:any) => {
                    return <a href={`/ann?hash=${row.hash}`} target="_blank"><img height="30%" width="30%" src={`https://annotator.kemtai.com/${row.path}`} /></a>
                }},
            ]}
        />)
        */
    }
}

export default observer(FacesSummary);
