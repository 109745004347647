import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable } from "mobx";

import { Keypoints, PointName, Point } from '../../skeleton-lib/keypoints'
import { drawSkeleton, ColorScheme, annColorScheme } from '../../skeleton-lib/skeleton-vis'
import config from '../../config'
import {Tooltip} from "react-tooltip";
import InfoPanel from './InfoPanel';
import manager from '../../Manager';
import { DataDesc } from '../../api_types';
import Modal from 'react-modal';
import ExamView from './ExamView';
import {min,max, assert} from '../../utils'
import {FaForward, FaBackward} from "react-icons/fa"
import { Controller } from './Controller';
import { VersionDef } from '../../ver';
import 'react-tooltip/dist/react-tooltip.css'
import logger from 'logger';

type AnnotatorProps = {
    isAdmin : boolean;
}

type UndoInfo = [PointName, Point | undefined];

const cs: ColorScheme = annColorScheme
const print = console.log

function nextStep(control:boolean,shift:boolean):number {
    if (control && shift) {
        return 50
    }
    if (shift) {
        return 20
    }
    if (control) {
        return 10
    }
    return 1
}

function loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => resolve(img)
        img.onerror = reject
        img.src = src
    })
}

async function loadKeypoints(url: string): Promise<Keypoints> {
    let response = await fetch(url);
    let data = await response.json()
    return Keypoints.fromJSON(data)
}



const PointNames : {[key:string]:string} = {
    "RWT" : "thumb side, right wrist",
    "LWT" : "thumb side, left wrist",
    "RWP" : "pinky side, right wrist",
    "LWP" : "pinky side, left wrist",
    "RWI": "internal, right wrist",
    "LWI": "internal, left wrist",
    "RWE": "external, right wrist",
    "LWE": "external, left wrist",

    "RKI" : "internal, right knee",
    "LKI" : "internal, left knee",
    "RKF" : "front, right knee",
    "LKF":  "front, left knee ",
    "RKE": "external, right knee",
    "LKE": "external, left knee",
    "RKB": "back side, right knee ",
    "LKB": "back side, left knee",

    "REI": "internal, right elbow",
    "LEI": "internal, left elbow",
    "REE": "external, right elbow",
    "LEE": "external, left elbow",

    "RAI": "internal, right ankle",
    "LAI": "internal, left ankle",
    "RAF": "front, right ankle",
    "LAF": "front, left ankle",
    "RAE": "external, right ankle",
    "LAE": "external, left ankle",
    "RAB": "back, right ankle",
    "LAB": "back, left ankle",

    "neck":             "neck, front, bottom",
    "neck_back":        "neck, back, bottom",
    "neck_top":         "neck, front, top",
    "back_neck_top":    "neck, back, top",
    "left_under_ear":   "neck under left ear",
    "right_under_ear":  "neck under right ear"
};

function pointDesc(point:string):string {
    if (PointNames[point]){
        return PointNames[point]
    }

    return point.replace(/_/g," ")
}

class Annotator extends Component<AnnotatorProps> {

    static defaultProps = {
        isAdmin: false,
    }


    outer: HTMLElement | null = null
    canvasRef: HTMLCanvasElement | null = null

    width: number = 0
    height: number = 0

    bodyImage: HTMLImageElement | null = null
    bodyKeypoints: Keypoints | null = null
    bodyWidth: number = 0
    bodyHeight: number = 0
    bodyOffset: number = 900

    data: DataDesc | undefined = undefined
    image: HTMLImageElement | null = null
    keypoints: Keypoints | null = null
    annotationChanged = false

    viewX: number = 0
    viewY: number = 0
    viewFactor: number = 1

    b0x: number = 0
    b0y: number = 0
    dragging = false
    movingPoint: PointName | null = null

    boxZoomActive = false
    boxZoomX1 = 0
    boxZoomY1 = 0
    boxZoomX2 = 0
    boxZoomY2 = 0

    undoStack: UndoInfo[] = []
    redoStack: UndoInfo[] = []

    cursor = "default"
    cursorPoint: PointName | null = null

    modified   = false;

    loading = false
    controller : Controller  = new Controller()
    mouseX: number=0;
    mouseY: number=0;
    version: VersionDef|null=null;


    constructor(props:AnnotatorProps) {
        super(props)

        makeObservable(this, {
            data: observable,
            cursorPoint : observable,
            mouseX : observable,
            mouseY : observable,        
        });

    }


    reset() {
        this.undoStack = []
        this.redoStack = []
        this.movingPoint = null
        this.viewFactor = 1
        this.viewX = 0
        this.viewY = 0
        this.cursor = "default"
    }

    view2image(x: number, y: number): [number, number] {
        return [x / this.viewFactor + this.viewX,  y / this.viewFactor + this.viewY]

    }
    canvas2bodykp(x: number, y: number): [number, number] {
        return [x - this.bodyOffset, y]
    }

    setViewX(v: number) {
        this.viewX = v
        /*
        if (this.viewX < MIN_V || this.maxViewX < MIN_V) {
            this.viewX = MIN_V
        } else {
            if (this.viewX > this.maxViewX) {
                this.viewX = this.maxViewX
            }
        }
        */
    }

    setViewY(v: number) {
        this.viewY = v;
        /*
        if (this.viewY < MIN_V || this.maxViewY < MIN_V) {
            this.viewY = MIN_V
        } else {
            if (this.viewY > this.maxViewY) {
                this.viewY = this.maxViewY
            }
        }
        */
    }

    setViewFactor(v: number) {
        this.viewFactor = v
        if (this.viewFactor < this.minviewFactor()) {
            this.viewFactor = this.minviewFactor()
        }
        //print("viewFactor:",this.viewFactor)
    }


    pointImageCoords(point: PointName): Point {
        assert(this.keypoints)
        assert(this.bodyKeypoints)

        if (this.keypoints.hasPoint(point)) {
            const p = this.keypoints.get(point)!
            return p.scale(-this.viewX, -this.viewY, this.viewFactor)
        } else {
            const p = this.bodyKeypoints.get(point)
            return p!.scale(this.bodyOffset, 0, 1)
        }
    }

    pointProximity(x: number, y: number) {
        if (!this.keypoints) {
            return null
        }

        if (x > this.bodyOffset) {
            let [x1, y1] = this.canvas2bodykp(x, y)
            const p = this.bodyKeypoints!.nearestPoint(x1, y1, 10)
            if (p && !this.keypoints.hasPoint(p)) {
                return p
            }
        } else {
            const [ix, iy] = this.view2image(x, y)
            const p = this.keypoints.nearestPoint(ix, iy, 10)
            if (p) {
                return p
            }
        }
        return null
    }




    onMouseDown = (e: any) => {
        if (e.button === 2) {
            this.startBoxZoom(e.clientX, e.clientY)
            return
        }

        if (e.button === 0) {
            const p = this.pointProximity(e.clientX, e.clientY)
            if (p) {
                this.movingPoint = p
                this.saveUndo(this.movingPoint)
                this.draw()
                return
            }

            this.b0x = e.clientX
            this.b0y = e.clientY
            this.dragging = true
        }


    }




    onMouseMove = (e: any) => {
        this.mouseX = e.clientX
        this.mouseY = e.clientY

        if (this.boxZoomActive) {
            this.setCursor("default");
            this.updateBoxZoom(e.clientX, e.clientY)
            return
        }


        if (this.movingPoint && e.button === 0) {
            // moving  point
            assert(this.keypoints)
            const [x, y] = this.view2image(e.clientX, e.clientY)
            this.keypoints[this.movingPoint] = new Point(x, y)
            this.draw()
            return
        }

        if (this.dragging && e.button === 0) {
            this.setCursor("grabbing");

            const dx = e.clientX - this.b0x
            const dy = e.clientY - this.b0y

            this.setViewX(this.viewX - dx / this.viewFactor)
            this.setViewY(this.viewY - dy / this.viewFactor)

            this.b0x = e.clientX
            this.b0y = e.clientY

            this.draw()
            return
        }

        this.setCursorPoint(this.pointProximity(e.clientX, e.clientY))
        if (this.cursorPoint) {
            this.setCursor("default");
        } else {
            this.setCursor("grab");
        }
    }

    setCursor(cursor: string) {
        if (this.cursor === cursor) {
            return
        }
        document.body.style.cursor = cursor;
        this.cursor = cursor
    }

    setCursorPoint(point: PointName | null) {
        if (this.cursorPoint === point) {
            return
        }
        this.cursorPoint = point
        this.draw()
    }

    onMouseLeave = (e: any) => {
        // print("onMouseLeave")
        if (this.boxZoomActive) {
            this.boxZoomActive = false
            this.draw()
            return
        }
    }

    onMouseUp = (e: any) => {
        //console.log("onMouseUp",e.button)
        if (e.button === 2) {
            this.updateBoxZoom(e.clientX, e.clientY)
            this.stopBoxZoom()
            return
        }

        if (this.movingPoint && e.button === 0) {
            assert(this.keypoints)

            this.updatePoint(this.movingPoint, e.clientX, e.clientY)
            logger.event("move-point", {point:this.movingPoint,aid:this.data?.aid})
            this.movingPoint = null
            this.annotationChanged = true
            this.draw()
            return
        }


        if (this.dragging && e.button === 0) {
            const dx = e.clientX - this.b0x
            const dy = e.clientY - this.b0y
            this.viewX -= dx / this.viewFactor
            this.viewY -= dy / this.viewFactor
            this.draw()
            this.dragging = false
        }
    }



    saveUndo(point: PointName) {
        assert(this.keypoints)
        const undo: UndoInfo = [point, this.keypoints[point]]
        this.undoStack.push(undo)
    }

    updatePoint(point: PointName, vx: number, vy: number) {
        assert(this.keypoints)
        this.modified = true;

        if (vx > this.bodyOffset) {
            //console.log("updatePoint: vx > this.bodyOffset")
            this.keypoints[point] = undefined
        } else {
            const [ix, iy] = this.view2image(vx, vy)
            //console.log("updatePoint:",ix, iy)

            this.keypoints[point] = new Point(ix, iy)
        }
    }

    undo = () => {
        assert(this.keypoints)
        if (this.undoStack.length <= 0) {
            return
        }
        const undoInfo = this.undoStack.pop()
        if (undoInfo) {
            const [point, value] = undoInfo

            const redo: UndoInfo = [point, this.keypoints[point]]
            this.keypoints[point] = value
            this.redoStack.push(redo)

        }
        this.draw()
    }

    redo = () => {
        assert(this.keypoints)
        //print("redo", this.redoStack.length)
        if (this.redoStack.length <= 0) {
            return
        }
        const undoInfo = this.redoStack.pop()
        if (undoInfo) {
            const [point, value] = undoInfo
            this.undoStack.push([point, this.keypoints[point]])
            this.keypoints[point] = value
        }
        this.draw()
    }

    minviewFactor(): number {
        return min(this.bodyOffset / this.image!.width,    this.height / this.image!.height) * 0.8
    }
    viewFactor100(): number {
        return min(this.bodyOffset / this.image!.width,    this.height / this.image!.height)
    }
    /*
    get maxViewX(): number {
        return (this.image!.width * this.viewFactor - this.bodyOffset) / this.viewFactor
    }
    get maxViewY(): number {
        return (this.image!.height * this.viewFactor - this.height) / this.viewFactor
    }
    */

    zoomIn = () => {
        logger.event("zoomIn",{aid:this.data?.aid})
        this.viewFactor *= 1.1
        this.setViewX(this.viewX + 0.05 * this.image!.width)
        this.setViewY(this.viewY + 0.05 * this.image!.height)
        this.draw()
    }
    zoomOut = () => {
        logger.event("zoomOut",{aid:this.data?.aid})

        this.setViewFactor(this.viewFactor / 1.1)
        this.setViewX(this.viewX - 0.05 * this.image!.width)
        this.setViewY(this.viewY - 0.05 * this.image!.height)
        this.draw()
    }
    zoom11 = () => {
        logger.event("zoom11",{aid:this.data?.aid})

        this.setViewFactor(this.viewFactor100())
        this.setViewX(0)
        this.setViewY(0)
        this.draw()
    }
    
    zoomBox() {
        logger.event("zoomBox",{aid:this.data?.aid})

        const vx1 = max(0, min(this.boxZoomX1, this.boxZoomX2))
        const vx2 = max(this.boxZoomX1, this.boxZoomX2)
        const vy1 = max(0, min(this.boxZoomY1, this.boxZoomY2))
        const vy2 = max(this.boxZoomY1, this.boxZoomY2)
        const [ix1, iy1] = this.view2image(vx1, vy1)
        const [ix2, iy2] = this.view2image(vx2, vy2)

        const factor = min(this.bodyOffset / (ix2 - ix1), this.height / (iy2 - iy1))
        //print(factor, this.viewFactor, this.bodyOffset /(ix2 - ix1),   this.height/(iy2 - iy1))

        this.setViewFactor(factor)
        this.setViewX(ix1)
        this.setViewY(iy1)


    }

    initZoom() {
        assert(this.keypoints)
        let box = this.keypoints.bbox()
        if (box) {
            box = box.enlarge(1.35)
            //print("box:", box.width, box.height)
            const factor = min(this.bodyOffset / box.width, this.height / box.height)

            const visibleWidth = this.bodyOffset / factor
            const visibleHeight = this.height    / factor

            const visibleCenterX = visibleWidth / 2
            const visibleCenterY = visibleHeight / 2

            const [cx,cy] = box.center()

            this.setViewFactor(factor)
            this.setViewX(cx-visibleCenterX)
            this.setViewY(cy-visibleCenterY)
        } else {
            this.setViewFactor(this.minviewFactor())
        }
    }



    onWheel = (e: any) => {
        if (e.deltaY > 0) {
            this.zoomOut()
        } else if (e.deltaY < 0) {
            this.zoomIn()
        }
    }

    onMouseClick = (e: any) => {
        //console.log("onMouseClick",e.button)
    }
    onKeyDown = (event: any) => {
        //console.log("onKeyDown", event.key)
        //console.log("onKeyDown",event)
        if (event.ctrlKey && event.key === "z") {
            this.undo()
            return
        }
        if (event.ctrlKey && event.key === "y") {
            this.redo()
            return
        }
        if (event.key === "ArrowRight") {
            this.next(nextStep(event.ctrlKey,event.shiftKey))
        }
        if (event.key === "ArrowLeft") {
            this.prev()
        }
        if (event.key === "ArrowDown") {
            this.zoomOut()
        }
        if (event.key === "ArrowUp") {
            this.zoomIn()
        }
        if (event.key === "Enter") {
            this.saveAndNext()
        }
    }

    initGeometry() {
        assert(this.outer)
        assert(this.canvasRef)
        this.width = this.outer.clientWidth
        this.height = this.outer.clientHeight - 100
        this.canvasRef.width = this.outer.clientWidth
        this.canvasRef.height = this.outer.clientHeight - 100
        this.bodyOffset = this.canvasRef.width - this.version!.body_width
    }

    onResize = () => {
        this.initGeometry()
        this.setViewFactor(this.viewFactor)
        this.draw()
    }

    startBoxZoom(x: number, y: number) {
        this.boxZoomActive = true
        this.boxZoomX1 = x
        this.boxZoomY1 = y
        this.boxZoomX2 = x
        this.boxZoomY2 = y
        this.draw()
    }

    updateBoxZoom(x: number, y: number) {
        this.boxZoomX2 = x
        this.boxZoomY2 = y
        this.draw()

    }

    stopBoxZoom() {
        this.boxZoomActive = false
        this.zoomBox()
        this.draw()
    }

    draw() {
        //console.log("draw called *****!!!!")

        if (!this.canvasRef) {
            return
        }
        if (!this.version) {
            return
        }

        assert(this.canvasRef)
        assert(this.bodyImage)
        assert(this.image)
        assert(this.bodyKeypoints)
        assert(this.keypoints)

        const ctx = this.canvasRef.getContext("2d")
        if (ctx) {
            ctx.fillStyle = "white"
            ctx.beginPath();
            ctx.rect(0, 0, this.canvasRef.width, this.canvasRef.height);
            ctx.fill()
            
            const sWidth = this.image.width - this.viewX
            const sHeight = this.image.height - this.viewY

            ctx.drawImage(this.image, this.viewX, this.viewY, sWidth, sHeight, 0, 0, sWidth * this.viewFactor, sHeight * this.viewFactor)
            drawSkeleton(ctx, this.keypoints, cs, true, -this.viewX, -this.viewY, this.viewFactor, this.keypoints.present(this.version!.points))

            //ctx.drawImage(image, 0, 0)
            ctx.drawImage(this.bodyImage, this.bodyOffset, 0)
            drawSkeleton(ctx, this.bodyKeypoints, cs, false, this.bodyOffset, 0, 1, this.keypoints.absent(this.version!.points))

            /// Draw cursor  point highlite
            if (this.cursorPoint) {
                let { x, y } = this.pointImageCoords(this.cursorPoint)
                //print("**********",this.cursorPoint,x,y)
                ctx.beginPath();
                ctx.arc(x, y, 6, 0, 2 * Math.PI, false);
                ctx.strokeStyle = "white";
                ctx.lineWidth = 2
                ctx.stroke();

            }

            /// Draw selected point highlite
            if (this.movingPoint) {
                let p = this.keypoints.get(this.movingPoint)
                if (p) {
                    let { x, y } = p.scale(-this.viewX, -this.viewY, this.viewFactor)
                    ctx.beginPath();
                    ctx.arc(x, y, 6, 0, 2 * Math.PI, false);
                    ctx.strokeStyle = "yellow";
                    ctx.fillStyle = "yellow";
                    ctx.fill();
                }
            }

            // Drow zoom box
            if (this.boxZoomActive) {
                ctx.beginPath();
                ctx.rect(this.boxZoomX1, this.boxZoomY1, this.boxZoomX2 - this.boxZoomX1, this.boxZoomY2 - this.boxZoomY1);
                ctx.strokeStyle = "black";
                ctx.stroke();
            }
            
            if (this.data!.flaggedout) {
                ctx.lineWidth = 10
                ctx.strokeStyle = 'rgba(255, 50, 50, 0.5)'
                ctx.beginPath();
                ctx.moveTo(0, 0);
                ctx.lineTo(this.canvasRef.width, this.canvasRef.height);
                ctx.stroke(); 

                ctx.beginPath();
                ctx.moveTo(this.canvasRef.width, 0);
                ctx.lineTo(0, this.canvasRef.height);
                ctx.stroke(); 

            }

        }
    }

    setLoading(mode:boolean){
        this.loading = mode
        if (this.loading){
            document.body.style.cursor = "wait"
        } else {
            document.body.style.cursor = "default"
        }
    }

    async loadData() {
        logger.event("load-data-start")
        const now = Date.now()
        this.setLoading(true)

        this.reset()
        this.data = await this.controller!.getData()
        if (!this.data) {
            return
        }
        this.version = manager.version(this.data.ver)

        this.bodyImage = await loadImage(this.version.body_image)
        this.bodyKeypoints = await loadKeypoints(this.version.body_points)
        this.bodyWidth = this.bodyImage.width
        this.bodyHeight = this.bodyImage.height

        this.initGeometry()

        //print("loadData", this.data);
        //print("**loading image", config.imageURLprefix + this.data?.image, this.data?.fid)
        this.image = await loadImage(config.imageURLprefix + this.data!.image)

        assert(this.data.keypoints)
        this.keypoints = this.data.keypoints.subset(this.version.points)
        for (let p of this.keypoints.absent()) {
            this.keypoints[p] = undefined
        }
        //console.log(">>>>>>>>>>>>",this.keypoints)
        this.initZoom()
        this.draw()
        this.setLoading(false)
        logger.event("load-data-end",{aid:this.data?.aid, "loading-time":Date.now()-now})
    }

    _saveAndNext = async (flaggedout:boolean=false) => {
        if (!this.keypoints) {
            return
        }

        if (this.loading) {
            return
        }

        if (this.data!.exam) {
            this.controller!.ExamDialog = true
            this.controller!.saveExamAttempt()
            return;
        }

        this.setLoading(true)
        await this.controller.save(this.keypoints, flaggedout)
        this.controller!.next()
        this.loadData()
    }



    flagOut = async () => {
        this._saveAndNext(true)
    }



    saveAndNext = async () => {
        logger.event("saveAndNext",{aid:this.data?.aid})

        this._saveAndNext(false)
    }


    prev = async () => {
        logger.event("prev",{aid:this.data?.aid})
        if (this.loading) {
            return
        }   
        if (!this.can_back()) {
            return
        }

        this.setLoading(true)
        this.controller.back()
        this.loadData()
    }

    can_next(): boolean {
        if (this.props.isAdmin) {
            return true
        }
        if (this.controller!.current < this.controller!.todo.length-1) {
            return true
        }
        return false
    }

    can_back(): boolean {
        return this.controller!.current>0
    }

    can_make_exam(): boolean {
        return  (manager.isKemtaiAdmin && !this.data?.exam) as boolean
    }



    next = async (step:number=0) => {
        logger.event("next",{aid:this.data?.aid})
        if (this.loading) {
            return
        }   
        if (!this.can_next()) {
            return
        }

        this.real_next(step)
    }

    real_next = async (step:number=0) => {
        this.setLoading(true)       
        this.controller!.next(step)
        this.loadData()
    }


    delete= async () => {
        logger.event("delete",{aid:this.data?.aid})
        if (this.loading) {
            return
        }
        this.setLoading(true)       
        await this.controller!.delete(this.data!.aid!)
        this.loadData()
    }

    goToAid = (aid:number) => {
        this.controller!.setAid(aid); 
        this.loadData()
    }

    makeExam= async () => {
        this.controller!.saveExam()
    }

    async init() {
        //console.log("init called *****!!!!")
        await this.loadData()

        //console.log(this.version!.body_image)

        if (this.canvasRef) {
            this.draw()
        }
    }


    componentWillUnmount() {
        if (this.canvasRef) {
            this.canvasRef.removeEventListener('click', this.onMouseClick)
            this.canvasRef.removeEventListener('mousedown', this.onMouseDown)
            this.canvasRef.removeEventListener('wheel', this.onWheel);
            this.canvasRef.removeEventListener('mouseup', this.onMouseUp)
            this.canvasRef.removeEventListener('mousemove', this.onMouseMove)
            this.canvasRef.removeEventListener('mouseout', this.onMouseLeave)
        }
        document.removeEventListener('keydown', this.onKeyDown)
        window.onresize = null
        document.body.style.cursor = "default";
    }


    componentDidMount() {
        //console.log("componentDidMount *****",window.location.pathname,window.location.search)
        this.controller = new Controller(window.location.search)

        window.onresize = this.onResize;
        assert(this.canvasRef)
        this.canvasRef.width = this.outer!.clientWidth
        this.canvasRef.height = this.outer!.clientHeight - 100

        this.canvasRef.addEventListener('click', this.onMouseClick)
        this.canvasRef.addEventListener('mousedown', this.onMouseDown)
        this.canvasRef.addEventListener('wheel', this.onWheel);
        this.canvasRef.addEventListener('mouseup', this.onMouseUp)
        this.canvasRef.addEventListener('mousemove', this.onMouseMove)

        this.canvasRef.addEventListener('mouseout', this.onMouseLeave)
        document.addEventListener('keydown', this.onKeyDown)

        this.canvasRef.oncontextmenu = function (e) {
            e.preventDefault();
        };
        this.init()
    }



    render() {
        //console.log(`Anootator.render  ${window.location}` )

        return (
            <div id="outerDiv" ref={el => this.outer = el} style={{ position: "absolute", width: "100%", height: "100%" }} >
                <InfoPanel />
                <div className="annInfo">{this.data?.ver} &nbsp; #{this.controller.current+1} 
                     {this.props.isAdmin && <>&nbsp; {this.data?.ann_summary} &nbsp;</>}
                     {this.props.isAdmin && this.data?.aid} &nbsp;
                     {this.props.isAdmin && (this.data?.basedon) && <FaBackward onClick={()=>this.goToAid(this.data!.basedon!)} /> }  &nbsp; 
                     {this.props.isAdmin && (this.data?.fixedby) && <FaForward  onClick={()=>this.goToAid(this.data!.fixedby!)} />}   &nbsp;     
                     {/* {this.data?.hidden_exam ? " ***" : ""}  */}
                     
                </div>
                <div className="pointInfo" style={{left: `${this.mouseX - 60}px`,  top: `${this.mouseY > 250 ? this.mouseY - 200 :  this.mouseY + 200 }px`}}>{this.cursorPoint ? pointDesc(this.cursorPoint) : ""}</div>
                <canvas ref={el => this.canvasRef = el} />
                <div id="controls" style={{ position: "absolute", width: "100%", height: "100" }}>
                    <img className="button" src="/icons/icons8-zoom-in-64.png" alt="zoom-in" onClick={this.zoomIn} data-tooltip-id="my-tooltip" data-tooltip-content="Zoom In" />
                    <img className="button" src="/icons/icons8-zoom-out-64.png" alt="zoom-out" onClick={this.zoomOut} data-tooltip-id="my-tooltip" data-tooltip-content="Zoom Out" />
                    <img className="button" src="/icons/icons8-zoom-to-actual-size-64.png" alt="zoom-out" onClick={this.zoom11} data-tooltip-id="my-tooltip" data-tooltip-content="Zoom out to the whole image" />
                    <img className="button" src="/icons/icons8-undo-64.png" alt="undo" onClick={this.undo} data-tooltip-id="my-tooltip" data-tooltip-content="Undo (Ctrl-Z)" />
                    <img className="button" src="/icons/icons8-redo-64.png" alt="redo" onClick={this.redo} data-tooltip-id="my-tooltip" data-tooltip-content="Redo (Ctrl-Y)" />
                    {this.can_back() &&
                        <img className="button" src="/icons/icons8-back-arrow-64.png" alt="back" onClick={this.prev} data-tooltip-id="my-tooltip" data-tooltip-content="discard all changes and go to the previose image" />
                    }
                    {this.can_next() &&
                       <img className="button" src="/icons/icons8-forward-button-64.png" alt="next" onClick={(e)=>this.next(nextStep(e.ctrlKey,e.shiftKey))} data-tooltip-id="my-tooltip" data-tooltip-content="discard all changes and go to the next image" />
                    }
                    {this.can_make_exam() &&
                        <img className="button" src="/icons/icons8-test-64.png" alt="makeExam" onClick={this.makeExam} data-tooltip-id="my-tooltip" data-tooltip-content="make exam" />
                    }
                    {
                        manager.isSuperPower && !this.data?.exam &&
                        <img className="button" src="/icons/icons8-delete-64.png" alt="delete" onClick={this.delete} data-tooltip-id="my-tooltip" data-tooltip-content="delete annotation" />

                    }
                    <img className="button" src="/icons/icons8-save-64.png" alt="save" onClick={this.saveAndNext} data-tooltip-id="my-tooltip" data-tooltip-content="Save and go to the next image" />
                    {this.props.isAdmin &&
                    <img className="button" src="/icons/icons8-cancel-64.png" alt="flagout" onClick={this.flagOut} data-tooltip-id="my-tooltip" data-tooltip-content="Flag Out this image" />
                    }

                </div>


                <Tooltip />

                <Modal
                          isOpen={this.controller!.ExamDialog}
                          contentLabel="Example Modal"
                >
                    <ExamView image={this.image!} data={this.data!} annotator={this} controller={this.controller} />
                </Modal>
                <Tooltip id="my-tooltip"/>
            </div>
        )
    }

}

export default observer(Annotator);
