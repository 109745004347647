
import {Keypoints, PointName} from './keypoints'

const limb_ratios : [PointName,PointName,number][] = [
    ["left_hip",      "right_hip", 14.684130099661633],
    ['left_shoulder', 'right_shoulder', 23.714863868900174],
    ['right_shoulder', 'right_hip', 35.45872558159769],
    ['left_shoulder', 'left_hip', 35.14085550072475],
    ['neck', 'top_of_the_head', 22.25348654019734], 
    ['chin', 'top_of_the_head', 16.396504942864063], 
    ['left_ear', 'right_ear', 11.485706218305486], 
    ['right_shoulder', 'right_elbow', 18.50796745625071], 
    ['right_elbow', 'right_wrist', 16.451164858139975], 
    ['left_shoulder', 'left_elbow', 18.54462757891701], 
    ['left_elbow', 'left_wrist', 16.35297530312522], 
    ['right_hip', 'right_knee', 27.161477611220555], 
    ['right_knee', 'right_ankle', 22.02438122631459], 
    ['left_hip', 'left_knee', 27.231333908623917], 
    ['left_knee', 'left_ankle', 21.949303234502054], 
    ['neck', 'spinal_cord_5', 40.28947204225279], 
    ['neck_back', 'spinal_cord_5', 44.442299837339554]
];


// sort array ascending
function _sort(arr : number[]) {    
    arr.sort((a, b) => a - b);
    return arr
}
function percentile(arr: number[], q:number){
    const sorted = _sort(arr);
    const pos = ((sorted.length) - 1) * q / 100;
    const base = Math.floor(pos);
    const rest = pos - base;
    if ((sorted[base + 1] !== undefined)) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};


export function predictedHeight(kp:Keypoints) {
    let predictors:number[] = []
    for(let pd of limb_ratios) { 
        let [p1,p2,l] = pd
        let ll = kp.dist(p1,p2)
        if (ll) { 
            const hh = ll / l * 100
            predictors.push(hh)
        }
    }
    if (predictors.length === 0) {
       return null
    }
    return percentile(predictors,85) * 1.08
}

export function heightNorm(pixelDistance:number,height:number) {
    return pixelDistance / height * 175
}

export default  predictedHeight;