


export const muscleOptions = ["ankle deviators","ankle dorsiflexors","ankle evertors","ankle invertors","ankle plantarflexors",
    "ankle pronators","ankle supinators","core","elbow extensors","elbow flexors","elbow pronators","elbow supinators",
    "hip abductors","hip adductors","hip extensors","hip flexors","hip horizonal abductors",
    "hip horizonal adductors","hip rotators","knee extensors","knee flexors","scapular depressors",
    "scapular elevators","scapular protractors","scapular retractors","shoulder abductors","shoulder adductors",
    "shoulder extensors","shoulder flexors","shoulder horizontal abductors","shoulder horizontal adductors",
    "shoulder rotators","spinal extensors","spinal flexors","spinal rotators","spinal side flexors",
    "wrist dorsiflexors","wrist palmarflexors","wrist pronators","wrist supinators"]
     


export const difficultyOptions =["Low","Medium","High"]

export const equipmentOptions = [
    "Exercise ball",
    "Kettlebell",
    "Chair",
    "Mat",
    "Towel",
    "Resistance Band",
    "Ankle Weights",
    "Weights",
    "BOSU",
    "Sturdy box",
    "Step",
    "Stick",
    "Round Foam Roller",
    "Air Stability Wobble Cushion"
]

export const exerciseGoalOptions = [
  "Strength-Isotonic",
  "Strength-Eccentric",
  "Strength-Isometric",
  "Balance/Proprioception",
  "Range of Motion",
  "Neural Mobilization",
  "Breathing",
  "Core",
  "Plyometrics/Explosive",
  "Stretch/Mobility",
  "Stabilization"
]

export const jointOptions = [
  "cervical spine",
  "thoracic spine", 
  "lumbar spine",
  "shoulder",
  "scapulothoracic",
  "elbow",
  "wrist/hand",
  "hip",
  "knee",
  "ankle"
]



export const movementOptions = [
    "abduction/adduction",
    "flexion/extension",
    "protraction/retraction",
    "supination/pronation",
    "dorsiflexion/plantar flexion",
    "palmarflexion/dosiflexion",
    "elevation/depression",
    "rotation",
    "side flexion",
    "inversion/eversion",
    "horizonal abduction/adduction",
    "circumduction",
    "deviation"
]