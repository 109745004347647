import React, { Component } from 'react';
import backend from "backend";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";

import {AiOutlineMonitor} from 'react-icons/ai'

import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

function numSort(a:number|undefined, b:number|undefined) : number {
    a = a || 0
    b = b || 0
    return  a < b ? 1 : a > b ? -1 : 0
}

function getNumSort(col:string) {
    return (rowA:any, rowB:any) : number  => {
        return numSort(rowA[col] , rowB[col])
    };
}


class PEAAdmin extends Component<{}> {
    data : any;
    value : number  = 1;
    loading : boolean = true

    constructor(props:{}) {
        super(props)

        makeObservable(this, {
            data: observable,
            value: observable,
            loading: observable,
        });

    }

    componentDidMount(){
        this.loadData()     
    }

    handleSelect = async (event:any) => {
        console.log("handleSelect:",event.target.value)

        await this.setState({subset:event.target.value})
        this.loadData()
    }

    loadData = async () => {
        this.loading = true
        this.data = await backend.peann_stats()
        this.loading = false
    }






    render() {       
        return (
            <Box>
               
            <DataTable data={this.data} 
                       keyField="uid" striped highlightOnHover 
                       defaultSortField="uid" 
                       progressPending={this.loading}
                       columns={[
                          //{name: 'ID',  selector: 'uid',  sortable: true, width: "80px"},
                          {name: 'email', selector: 'email', grow:2, sortable: true },
                          {name: 'name', selector: 'name', grow:2, sortable: true },

                          {name: '# annotations today',        selector: 'today', sortable: true, sortFunction:getNumSort("today") },
                          {name: 'work hours today',        selector: 'hours_today', sortable: true, sortFunction:getNumSort("hours_today") },

                          {name: '# annotations last 7 days',  selector: 'day7', sortable: true , sortFunction:getNumSort("day7")},
                          {name: 'work hours last 7 days',        selector: 'hours_week', sortable: true, sortFunction:getNumSort("hours_week") },

                          {name: '# annotations last 30 days', selector: 'day30', sortable: true , sortFunction:getNumSort("day30")},
                          {name: 'work hours last 30 days',        selector: 'hours_month', sortable: true, sortFunction:getNumSort("hours_month") },

                          {name: '# total annotations', selector: 'ann', sortable: true  , sortFunction:getNumSort("ann")},
                          
                          {
                            name: 'Browse', width: "80px",compact:true,
                            cell: (row:any) =>  <Link to={`/pea/ann?uid=${row.uid}&email=${row.email}`}>
                                                                  <AiOutlineMonitor data-tip="browse"/> 
                                                                </Link>,
                          }
                  ]}/>
              </Box>
        )
    }
}


export default observer(PEAAdmin);