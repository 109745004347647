import React from "react";
import { Component } from "react";
import { observer } from "mobx-react";
import backend from '../../../backend';
import { makeObservable, observable, toJS } from "mobx";



class FaceDetails extends Component {
    face_id : string = ""
    data : any = null

    constructor(props:any){
        super(props)

        makeObservable(this, {
            data: observable,
        });


        this.face_id = window.location.search
    }

    componentDidMount() {
        this.load()
    }

    async load() {
        this.data = await backend.faces_details(this.face_id)
        console.log("data",toJS(this.data))
    }


    render() {

        if (!this.data) {
            return null
        }

        return (<>
        {
            this.data.map((item:any) => {
                return <div key={item.video}>
                    <img height="30%" width="30%" src={`https://annotator.kemtai.com/${item.img_path}`} />
                    <br/>{Math.round(item.sim)}
                    <a href={`/ann?vid=${item.vid}`}>
                       {item.video.replace("SKELETON/","")}</a></div>
            })
        }        
        </>)
    }
}

export default observer(FaceDetails);
