import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import manager from './Manager';

ReactDOM.render(
        <Router history={manager.history}>
            <App />
        </Router>

    ,   document.getElementById('root')
    
);

serviceWorker.unregister();

