import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable } from "mobx";
import { RVAnnSpec } from '../../api_types';
import backend from '../../backend';


class RegressionVideoAnnotator extends Component<{}> {
    rvid : number = 0
    url : string = ""
    coachVideoUrl : string = ""
    video : HTMLVideoElement | null = null
    videoSpeed = 1.0

    ann: string  = ""

    constructor(props:{}) {
        super(props) 
        makeObservable(this, {
            url : observable,
            videoSpeed: observable,    
            coachVideoUrl : observable,    
        });

    }


    setSpeed(x:number) {
        this.videoSpeed = x
        if (this.video) {
            this.video.playbackRate = x 
        }
    }

    get videoUrl() : string{
        return this.url
    }

    
    onChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        console.log("onChange:",e.target.value)
        this.ann = e.target.value
    }

    onKeyPress = (event:React.KeyboardEvent) => {
        console.log("onKeyPress:",event.key)
        if (!/[0-9]/.test(event.key)) {
             event.preventDefault();
        }
        if(event.key === 'Enter' && this.ann.length > 0) {
            this.saveOK()
        }
    }


    saveOK = () => {
        backend.rvann_save(this.rvid, {repeats:Number(this.ann), status:"ok"})
        this.load()
    }    
    saveBad = () => {
        backend.rvann_save(this.rvid, {status:"bad"})
        this.load()
    }    

    async load() {
        const data : RVAnnSpec|null = await backend.rvann_todo()
        console.log("====================================")
        console.log("load:",data)
        console.log("====================================")
        if (data) {
            this.rvid = data.rvid
            this.url = data.url
            this.coachVideoUrl = data.url

            this.setSpeed(this.videoSpeed)
        }
    }

    componentDidMount() {
        this.load()
    }


    render() {
        //console.log(`Anootator.render  ${this.videoUrl}` )

        return (
                <div>
                     <video ref={ref=>this.video=ref} src={this.videoUrl} controls width="640px" height="480px"/>
                     
                     <video  className='coach-video'                         src={this.coachVideoUrl} autoPlay />
                     <div>
                     <span style={{verticalAlign:"110%"}}>             
                                  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                                  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
        {[0.25,0.5,1,2,4].map(
             v => <button onClick={e=>this.setSpeed(v)} key={v}
                         style={ this.videoSpeed===v ? {color:"blue", fontWeight:"bold" }:{}}>
                  {v}x 
                  </button>
        )}


                     </span>
                     </div>

                     <div className='rv-form'>
                         # Repeats:    
                         <input className='rv-button' size={4} key={this.rvid}
                          onKeyPress={this.onKeyPress}
                          onChange={this.onChange} 
                         />
                         <button className='rv-button' onClick={this.saveOK}>Save</button>
                         <button className='rv-button' onClick={this.saveBad}>X - bad video</button>
                     </div>
                </div>
        )
    }

}
export default observer(RegressionVideoAnnotator);
