import * as _ from "lodash"
import { Keypoints } from './skeleton-lib/keypoints'
import {predictedHeight, heightNorm} from './skeleton-lib/height'
import {ExamMetrics} from './api_types'



export function min(x: number, y: number): number {
    if (x < y) {
        return x
    } else {
        return y
    }
}

export function max(x: number, y: number): number {
    if (x > y) {
        return x
    } else {
        return y
    }
}




export function randomString(len:number=12):string {
    let res = ""
    for(let i=0;i<len;i++){
        const r = Math.random() * 16 | 0;
        res += r.toString(16)
    }
    return res      
}

export function parseURL(url:string) {
    let parser = document.createElement('a');
    let searchObject:any = {}
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    const queries = parser.search.replace(/^\?/, '').split('&');
    for(let i = 0; i < queries.length; i++ ) {
        const split = queries[i].split('=');
        searchObject[split[0]] = split[1];
    }
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash
    };
}

export function examMetrics(keypoints:Keypoints, exam:Keypoints) {
    const metrics = new ExamMetrics()
    const height = predictedHeight(exam)!;

    const intersection = _.intersection(keypoints.points(), exam.points());
    const union = _.union(keypoints.points(), exam.points());
    metrics.iou = (intersection.length/union.length)*100

    let e = 0
    let c = 0
    let e2=0
    for(let point of keypoints.points()) {
        if (exam.hasPoint(point)) {
            const d_px = exam.get(point)!.dist( keypoints.get(point)! )
            const d = heightNorm(d_px, height)
            e+=d; e2 += d*d; c++;
            metrics.maxError = max(d,metrics.maxError)
        }
    }
    metrics.averageError = e / c
    metrics.rmsd = e2/c 
    if (//true || 
        metrics.maxError < 5 && metrics.iou > 95 && metrics.averageError<1.5 && metrics.rmsd < 4) {
        metrics.pass = true
    } else {
        metrics.pass = false
    }
    return metrics
}

export function sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function assert(condition: any, msg?: string): asserts condition {
    if (!condition) {
      throw new Error(msg);
    }
}
  