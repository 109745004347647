import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable } from "mobx";
import backend from '../../backend';



class EVideoAnnotator extends Component<{}> {
    uuid : string = ""
    video : HTMLVideoElement | null = null
    videoSpeed = 1.0

    constructor(props:{}) {
        super(props)

 
        makeObservable(this, {
            uuid : observable,
            videoSpeed: observable,        
        });

    }

    
    setSpeed(x:number) {
        this.videoSpeed = x
        if (this.video) {
            this.video.playbackRate = x 
        }
    }

 
    get videoURL() : string{
        return `https://app.kemtai.com/media/exercises/${this.uuid}/skel.webm`
    }

    save(ann:string) {
        backend.evideoSave(this.uuid, ann)
        this.load()
    }    

    async load() {
        const data = await backend.evideoTodo()
        if (data) {
            this.uuid = data.uuid
            this.setSpeed(this.videoSpeed)
        }
    }

    componentDidMount() {
        this.load()
    }

    render() {
        //console.log(`EVideoAnnotator.render  ${this.videoURL}` )
        if (!this.uuid) {
            return null
        }
        return (
                <div>
                     <video ref={ref=>this.video=ref} src={this.videoURL} autoPlay controls loop width="1200px" height="800px"/>
                     <div>
                     <img className="button" src="/icons/icons8-red-circle-48.png"    alt="" onClick={()=>this.save("bad")} />
                     <img className="button" src="/icons/icons8-yellow-circle-48.png" alt="" onClick={()=>this.save("borderline")}  />
                     <img className="button" src="/icons/icons8-green-circle-48.png"  alt="" onClick={()=>this.save("ok")} />
                     <span style={{verticalAlign:"110%"}}>             
                                  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                                  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
        {[0.25,0.5,1,2,4].map(
             v => <button key={v} onClick={e=>this.setSpeed(v)} 
                         style={ this.videoSpeed===v ? {color:"blue", fontWeight:"bold" }:{}
                         }>
                  {v}x 
                  </button>
        )}        
                     </span>
                     </div><div>
                     </div>
                    
                     <div>
                       {/*  <p>video:{this.videoURL}</p>
                            <p>speed: {this.videoSpeed}</p> */}
                     </div>
                    
                </div>
        )
    }

}
export default observer(EVideoAnnotator);
