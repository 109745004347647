import React, { Component } from 'react';
import backend from "../backend";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";

import {AiOutlineMonitor} from 'react-icons/ai'

import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import {allVersions} from "../ver"
import manager from '../Manager';
import { Box } from '@mui/material';

function numSort(a:number|undefined, b:number|undefined) : number {
    a = a || 0
    b = b || 0
    return  a < b ? 1 : a > b ? -1 : 0
}

function getNumSort(col:string) {
    return (rowA:any, rowB:any) : number  => {
        return numSort(rowA[col] , rowB[col])
    };
}


type AdminUsersState = {
    subset : string
}
class AdminUsers extends Component<{},AdminUsersState> {
    data : any;
    value : number  = 1;
    loading : boolean = true

    constructor(props:{}) {
        super(props)

        this.state = {
            subset:"active"
        }
        makeObservable(this, {
            data: observable,
            value: observable,
            loading: observable,
        });

    }

    componentDidMount(){
        this.loadData()     
    }

    handleSelect = async (event:any) => {
        console.log("handleSelect:",event.target.value)

        await this.setState({subset:event.target.value})
        this.loadData()
    }

    loadData = async () => {
        this.loading = true
        //console.log("loadData:",this.state.subset)
        this.data = await backend.user_stats(this.state.subset)
        this.loading = false
    }




    updateVer = async (uid:number, ver:string) => {
        console.log(`****** ${uid} ==> ${ver}`)
        await backend.updateUserVersion(uid,ver)
        this.loadData()
    }
    
    showVer = (row:any) => {
        if (manager.isKemtaiAdmin) {
            return (
                <select value={row.ver} onChange={event=>this.updateVer(row.uid,event.target.value)} >
                { allVersions.map( (v:string)=><option key={v}>{v}</option>) }
                </select>
            )
        } else {
            return row.ver
        }
    }
    


    render() {       
        return (
            <Box>
                <Box>
            Show: 
            <select value={this.state.subset} onChange={this.handleSelect}> 
                <option value="active"> active</option>
                <option value="all"> all</option>
                <option value="ponka"> ponka</option>
                <option value="oasis"> oasis</option>
                <option value="keymakr"> keymakr</option>
                <option value="kemtai"> kemtai</option>
                <option value="kemtai-new"> kemtai 2</option>
                <option value="impact"> impact</option>

            </select> annotators
            </Box>
            <DataTable data={this.data?.ann_by_user} 
                       keyField="uid" striped highlightOnHover 
                       defaultSortField="uid" 
                       progressPending={this.loading}
                       columns={[
                          {name: 'ID',  selector: 'uid',  sortable: true, width: "80px"},
                          {name: 'email', selector: 'email', grow:2, sortable: true },
                          {name: 'name', selector: 'name', grow:2, sortable: true },
                          {name: 'group', selector: 'group',   sortable: true },
                          {name: 'ver', selector: this.showVer,   sortable: true },

                          {name: '# annotations today',        selector: 'today', sortable: true, sortFunction:getNumSort("today") },
                          {name: 'work hours today',        selector: 'hours_today', sortable: true, sortFunction:getNumSort("hours_today") },

                          {name: '# annotations last 7 days',  selector: 'day7', sortable: true , sortFunction:getNumSort("day7")},
                          {name: 'work hours last 7 days',        selector: 'hours_week', sortable: true, sortFunction:getNumSort("hours_week") },

                          {name: '# annotations last 30 days', selector: 'day30', sortable: true , sortFunction:getNumSort("day30")},
                          {name: 'work hours last 30 days',        selector: 'hours_month', sortable: true, sortFunction:getNumSort("hours_month") },

                          {name: '# total annotations', selector: 'ann', sortable: true  , sortFunction:getNumSort("ann")},
                          {name: 'exam score ', selector: 'exam_score', sortable: true  , sortFunction:getNumSort("exam_score")},
                          {name: 'exam score last 30 days', selector: 'exam_score30', sortable: true  , sortFunction:getNumSort("exam_score30")},

                          {
                            name: 'Browse', width: "80px",compact:true,
                            cell: (row:any) =>  <Link to={`/ann?user=${row.email}`}>
                                                                  <AiOutlineMonitor data-tip="browse"/> 
                                                                </Link>,
                          }
                  ]}/>
              </Box>
        )
    }
}


export default observer(AdminUsers);