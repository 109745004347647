import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { useState } from 'react';


import GlobalStyles from './globalStyles';
import { light, dark } from './themes';

export const darkScrollbar = () => ({
	'&::-webkit-scrollbar-track': {
		borderRadius: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '10px',
		backgroundColor: '#000',
	},
});

type ThemePalette = 'dark' | 'light';

type ThemeContextValue = {
	palette: ThemePalette;
	setPalette: (palette: ThemePalette) => void;
};

export const ThemeContext = React.createContext<ThemeContextValue>({} as ThemeContextValue);

export const useLocalStorage = (key: string, initialValue = '') => {
	const [value, setValue] = useState(() => initialValue);
	return {
		value,
		setValue,
	};
};


export const ThemeProvider: React.FC = ({ children }) => {
	const [palette, setPalette]= useState(() => 'dark');
	const theme = palette === 'dark' ? createTheme(dark) : createTheme(light);

	return (
		<ThemeContext.Provider
        value={{
            palette : palette as ThemePalette,
            setPalette,
        }}
    >
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />

				{children}
			</MuiThemeProvider>
		</ThemeContext.Provider>
	);
};
