import React, { Component } from 'react';
import { GoogleLoginButton } from 'ts-react-google-login-component';
import config from '../config';
import backend from '../backend';
import manager from '../Manager'
import {User} from '../api_types'

type LoginProps = {
    onsucc? : string 
}
  

class Login extends Component<LoginProps> {

    preLoginTracking(): void {
        console.log('Attemp to login with google');
    }
 
    errorHandler(error: string): void{
        // handle error if login got failed...
        console.error("errorHandler:",error)
    }
 
    responseGoogle = async (googleUser: gapi.auth2.GoogleUser) => {
        const id_token = googleUser.getAuthResponse(true).id_token
        const googleId = googleUser.getId()
        if (googleId) {
            const userCredentials = await backend.login(id_token)
            if (userCredentials) {
                console.log("Login success =>",this.props.onsucc)
                manager.onLogin(userCredentials as User, this.props.onsucc)
            }
        }
    }
    render() {
        const clientConfig = { client_id: config.googleClientId }
        //console.log("Login =>",this.props.onsucc)

        return (
        <div className="loginBox">
            
            <p>Please login with your google account </p>
            <div className="loginButton">
                <GoogleLoginButton
                    responseHandler={this.responseGoogle}
                    clientConfig={clientConfig}
                    preLogin={this.preLoginTracking}
                    failureHandler={this.errorHandler}
                />
            </div>
        </div>
        )
    }
}

export default Login;