import React, { Component } from 'react';
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";
import {Tab,Tabs,AppBar,Box} from '@mui/material';
import {ThemeProvider} from '../theme'
import AdminUsers from './AdminUsers';
import AdminDataSets from './AdminDataSets';

function a11yProps(index:number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props:any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div 

        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 , bgcolor: 'background.paper',}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
   
type AdminProps = {

}



class Admin extends Component<AdminProps> {
    data : any;
    value : number  = 0;

    constructor(props:AdminProps) {
      super(props)
      makeObservable(this, {
        data: observable,
        value: observable,
      });
    }


    render() {

        const handleChange = (event:any, newValue:number) => {
            this.value = newValue;
        };
        
        
        return (
        <ThemeProvider>
        <div className="admin" >
            <AppBar position="static">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

              <Tabs value={this.value} onChange={handleChange}   aria-label="simple tabs example">  
                  <Tab label="Annotators" {...a11yProps(0)} />
                  <Tab label="Datasets"   {...a11yProps(1)} /> 
              </Tabs>
            </Box>
            </AppBar>


            <TabPanel value={this.value} index={0}>
                <AdminUsers/>
            </TabPanel>

            <TabPanel value={this.value} index={1}>
                <AdminDataSets/>
            </TabPanel>
        
        </div>
        </ThemeProvider>
        )
    }
}


export default observer(Admin);