import  {Component} from 'react';
import { observer } from "mobx-react";

import './App.css';
import manager from './Manager'
import { Route, Switch, Redirect } from 'react-router-dom';
import React from 'react';

import Login from './components/Login'
//import Admin from './components/Admin';

import Annotator from './components/annotator/Annotator';
import VAnnotator from './components/annotator/VAnnotator';
import EVideoAnnotator from './components/annotator/EVideoAnnotator';
import config from './config';
import Admin2 from './components/Admin2';
import RegressionVideoAnnotator from './components/annotator/RegressionVideoAnnotator';
import PEAnnotator from './components/annotator/PEA/PEAnnotator';
import FeedbackAnnotator from './components/annotator/Feedback/FeedbackAnnotator';

import { configure } from "mobx"
import PEAQuery from './components/annotator/PEA/PEAQuery';
import PEAAdmin from './components/annotator/PEA/PEAAdmin';
import FacesSummary from 'components/annotator/Faces/FacesSummary';
import FacesDetais from 'components/annotator/Faces/FacesDetails';

configure({
    enforceActions: "never",
})



class App extends Component {
    componentDidMount() {
        console.log(`%cKemtai Annotator v.${config.version}`,"background-color:cyan")
    }

    render () {
        //console.log(`App.render  isLoggedIn=${manager.isLoggedIn} ${window.location}` )
        //const url = parseURL(window.location.href);
        if (!manager.isLoggedIn) {
            return <Login onsucc={`${window.location.pathname}${window.location.search}`}/>
        }

        return ( 
             <Switch>
                <Route path="/login">    <Login/> </Route>
                {manager.isAdmin &&      <Route path="/admin"><Admin2/></Route>}
                <Route path="/ann">      <Annotator isAdmin={manager.isAdmin} /> </Route>
                <Route path="/vann">     <VAnnotator/>       </Route>
                <Route path="/rvann">     <RegressionVideoAnnotator/>       </Route>
                <Route path="/faces/summary/">     <FacesSummary/>       </Route>
                <Route path="/faces/details/">     <FacesDetais/>       </Route>
                <Route path="/faces/">     <FacesSummary/>       </Route>

                <Route path="/peann">     <PEAnnotator/>       </Route>
                <Route path="/pea/ann">     <PEAnnotator/>       </Route>
                <Route path="/peaq">     <PEAQuery/>       </Route>
                <Route path="/pea/admin">     <PEAAdmin/>       </Route>

                <Route path="/evideo">   <EVideoAnnotator/>  </Route>

                <Route path="/feedback/ann">     <FeedbackAnnotator/>       
            </Route>


                <Redirect to="/ann" />
             </Switch>
        )
    }
}


export default observer(App);
