import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable,toJS } from "mobx";
import backend from '../../../backend';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

const difficultyOptions =["Low","Medium","High"]

const exerciseGoalOptions = [
  "Strength-Isotonic",
  "Strength-Eccentric",
  "Strength-Isometric",
  "Balance/Proprioception",
  "Range of Motion",
  "Neural Mobilization",
  "Breathing",
  "Core",
  "Plyometrics/Explosive",
  "Stretch/Mobility",
  "Stabilization"
]

const jointOptions = [
  "cervical spine",
  "thoracic spine", 
  "lumbar spine",
  "shoulder",
  "scapulothoracic",
  "elbow",
  "wrist/hand",
  "hip",
  "knee",
  "ankle"
]



const movementOptions = [
    "abduction/adduction",
    "flexion/extension",
    "protraction/retraction",
    "supination/pronation",
    "dorsiflexion/plantar flexion",
    "palmarflexion/dosiflexion",
    "elevation/depression",
    "rotation",
    "side flexion",
    "inversion/eversion",
    "horizonal abduction/adduction",
    "circumduction",
    "deviation"
]

type QueryT = {
    exerciseGoal? : string,
    movement? : string,
    joint? : string,
    difficulty? : string,
    passive? : boolean,
    balance? : boolean,
    non_weight_bearing? : boolean
}

const defaultQuery:QueryT = {
    exerciseGoal : "unselected",
    movement : "unselected",
    joint : "unselected",
    difficulty : "unselected",
    passive : false,
    balance : false,
    non_weight_bearing : false
}

type MySelectProps = {
  field : string
  title : string
  options : string[]
  annotation: any
}

const SelectMenu = observer((props : MySelectProps) => {
  
  return (<>
    <label>{props.title}: 
      <Select 
            value={props.annotation[props.field]}
            onChange={(event: SelectChangeEvent<string>) => {
                     props.annotation[props.field] = event.target.value
            }} 
            className='pe-input w500'
      > 
        <MenuItem key="unselected" value="unselected">
          <ListItemText primary={`-- select ${props.title} --`}/>
        </MenuItem>

        {props.options.map((name) => (
        <MenuItem key={name} value={name}>
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
    </label><br/> 
  </>)
})


type QueryFormProps = {
    query : QueryT
    search : () => void
}

function QueryForm(props : QueryFormProps){ 

    const handleCheckboxChange = (e : any) => {
        (props.query as any)[e.target.id] = e.target.checked
    }
    const reset = () => {
        for (const key in defaultQuery) {
            (props.query as any)[key] = (defaultQuery as any)[key]
        }

    }
    return (
      <div className='pe-form'>       
       <SelectMenu title='exercise goal' field='exerciseGoal' options={exerciseGoalOptions} annotation={props.query}></SelectMenu>       
       <SelectMenu title='movement' field='movement' options={movementOptions} annotation={props.query}></SelectMenu>
       <SelectMenu title='joint' field='joint' options={jointOptions} annotation={props.query}></SelectMenu>
       <SelectMenu title='difficulty' field='difficulty' options={difficultyOptions} annotation={props.query}></SelectMenu>
       
       <br/>

       <label>passive:<input type="checkbox" className='pe-input' id="passive" onChange={handleCheckboxChange}/></label>
       <label>balance:<input type="checkbox" className='pe-input' id="balance" onChange={handleCheckboxChange}/></label> 
       <label>non weight bearing:<input type="checkbox" className='pe-input' id="non_weight_bearing" onChange={handleCheckboxChange}/></label>
       
       <br/> 
       {/*<button className='rv-button' onClick={reset}>Reset</button>*/}
       <button className='rv-button' onClick={props.search}>Search</button>

      </div>     
    )
}


type ExThumbProp = {
    exid : number
    name : string 
    uuid : string
}

class ExThumb extends Component<ExThumbProp> {
    videoRef : HTMLVideoElement|null = null;

    onMouseEnter = () => {
        if (this.videoRef) {
            this.videoRef.play()
        }
    }

    onMouseLeave = () => {
        if (this.videoRef) {
            this.videoRef.pause()
        }   
    }

    name() {
        if (this.props.name.length > 20) {
            return this.props.name.substr(0,20) + "..."
        } else {
            return this.props.name
        }
    }

    render(){
       return (<div key={this.props.uuid} style={{margin:"5px"}}>   
            <h4> {this.name()} (<a href={`https://dashboard.kemtai.com/exercises/exercise/${this.props.exid}/`} target="_blank">
               {this.props.exid}</a>)</h4>
            <video 
                ref={el => this.videoRef = el}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
        
                src={`https://app.kemtai.com/media/exercises/${this.props.uuid}/thumbnail-131-loop.webm`}></video>

       </div>)
    }

}

function QueryResults({data} : {data : null | any[]}) {
    if (data === null) {
        return null
    }
    return (
    <><h3>{data.length} exercises </h3>
    <div style={{display: "flex", flexWrap:"wrap"}}>
       {data.map( (exercise:any)=>{return <ExThumb {...exercise} ></ExThumb> 
       })}
    </div></>)
}

class PEAQuery extends Component<{}> {
    query : any = {
        exerciseGoal : "unselected",
        movement : "unselected",
        joint : "unselected",
        difficulty : "unselected",
        passive : false,
        balance : false,
        non_weight_bearing : false
    }

    data : null | any[] = null

    constructor(props:{}) {
        super(props) 
        makeObservable(this, {
            query : observable,
            data : observable,
        });

    }

    search = async () => {
        console.log("search",toJS(this.query))
        
        this.data = await backend.peann_query(this.query);
        console.log("data:", toJS(this.data))
    }

    render() {
        return (<>
            <QueryForm query={this.query} search={this.search} />
            <QueryResults data={this.data} />
        </>)
    }
}


export default observer(PEAQuery);
