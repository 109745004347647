import { PointName } from "./skeleton-lib/keypoints"

export type VersionDef = {
    body_image  : string,
    body_points : string,
    body_width  : number,
    points      : PointName[]
}


export const allVersions = ["v2","tasq","v3","v4","v5","v5baby","newpoints","v75","v111","v113","hands"]

export const versions : {[key:string]:VersionDef} = {
    v1 :  {
        body_image  : "body.png",
        body_points : "body.json",
        body_width  : 400,
        points : ['nose', "top_of_the_head", "chin","neck",
        "spinal_cord_1", "spinal_cord_2", "spinal_cord_3", "spinal_cord_4", "spinal_cord_5",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        "left_finger","right_finger", "left_thumb", "right_thumb"  , "neck_back"]
    },
    v2 :  {
        body_image : "body2.png",
        body_points : "body2.json",
        body_width  : 600,
        points : ['nose', "top_of_the_head", "chin","neck",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        "left_finger","right_finger", "left_thumb", "right_thumb"  , "neck_back",
        "back_1","back_2","back_3","back_4","back_5",
        "front_1","front_2","front_3","front_4","front_5"]
    },
    v3 :  {
        body_image : "body2.png",
        body_points : "body2-v3.json",
        body_width  : 600,
        points : ['nose', "top_of_the_head", "chin","neck",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        "left_finger","right_finger", "left_thumb", "right_thumb"  , "neck_back",
        "back_1","back_2","back_3","back_4","back_5",
        "front_1","front_2","front_3","front_4","front_5",

        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",

    ]
    },
    v4 :  {
        body_image : "body2.png",
        body_points : "body2-v3.json",
        body_width  : 600,
        points : ['nose', "top_of_the_head", "chin","neck",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        //"left_finger","right_finger", "left_thumb", "right_thumb"  , 
        "neck_back",
        "back_1","back_2","back_3","back_4","back_5",
        "front_1","front_2","front_3","front_4","front_5",

        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
    ]
    },
    v5 :  {
        body_image : "body2.png",
        body_points : "body2-v5.json",
        body_width  : 600,
        points : ['nose', "top_of_the_head", "chin","neck",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        //"left_finger","right_finger", "left_thumb", "right_thumb"  , 
        "neck_back",
        "back_1","back_2","back_3","back_4","back_5",
        "front_1","front_2","front_3","front_4","front_5",

        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
        "left_smalltoe", "right_smalltoe",
    ]
    },

    v5baby :  {
        body_image : "body2.png",
        body_points : "body2-v5.json",
        body_width  : 600,
        points : ['nose', "top_of_the_head", "chin","neck",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        //"left_finger","right_finger", "left_thumb", "right_thumb"  , 
        "neck_back",
        "back_1","back_2","back_3","back_4","back_5",
        "front_1","front_2","front_3","front_4","front_5",

        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
        "left_smalltoe", "right_smalltoe",
    ]
    },


    
    v30 :  {
        body_image : "body2.png",
        body_points : "body2-v3.json",
        body_width  : 600,
        points : [
        "neck","neck_back",
        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
    ]
    },
    v75 :  {
        body_image : "body2.png",
        body_points : "body2-v75.json",
        body_width  : 600,
        points : ['nose', "top_of_the_head", "chin","neck",
        'left_eye', 'right_eye',  "left_ear", "right_ear",
        'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
        'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle',
        "left_bigtoe", "right_bigtoe", "left_heel","right_heel",
        "neck_back",
        "back_1","back_2","back_3","back_4","back_5",
        "front_1","front_2","front_3","front_4","front_5",

        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",

        "RWT",
        "LWT",
        "RWP",
        "LWP",
        "RWI",
        "LWI",
        "RWE",
        "LWE",
        "RKI",
        "LKI",
        "RKF",
        "LKF",
        "RKE",
        "LKE",
        "RKB",
        "LKB",
        "REI",
        "LEI",
        "REE",
        "LEE",
        "RAI",
        "LAI",
        "RAF",
        "LAF",
        "RAE",
        "LAE",
        "RAB",
        "LAB"
    ]
    },
    tasq :  {
        body_image : "body3.png",
        body_points : "body2-tasq.json",
        body_width  : 900,
        points : [
        "left_shoulder_hand","right_shoulder_hand", "left_shoulder_neck","right_shoulder_neck",
        "left_under_ear", "right_under_ear", "neck_top", "back_neck_top",
        "RWT",        "LWT",        "RWP",        "LWP",        "RWI",        "LWI",        "RWE",        "LWE",
        "RKI",        "LKI",        "RKF",        "LKF",        "RKE",        "LKE",        "RKB",        "LKB",
        "REI",        "LEI",        "REE",        "LEE",
        "RAI",        "LAI",        "RAF",        "LAF",        "RAE",        "LAE",        "RAB",        "LAB"
    ]
    },

    newpoints :  {
        body_image : "body3.png",
        body_points : "body2-tasq.json",
        body_width  : 900,
        points : [
        "RWT",        "LWT",        "RWP",        "LWP",        "RWI",        "LWI",        "RWE",        "LWE",
        "RKI",        "LKI",        "RKF",        "LKF",        "RKE",        "LKE",        "RKB",        "LKB",
        "REI",        "LEI",        "REE",        "LEE",
        "RAI",        "LAI",        "RAF",        "LAF",        "RAE",        "LAE",        "RAB",        "LAB"
    ]
    },


    // hands :  {
    //     body_image : "body2.png",
    //     body_points : "body2-hands.json",
    //     body_width  : 600,
    //     points : [
    //         'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_wrist', 'right_wrist',
    //         "left_shoulder_hand","right_shoulder_hand",
    //         "left_finger","right_finger", "left_thumb", "right_thumb" ,

    //         "RWT",
    //         "LWT",
    //         "RWP",
    //         "LWP",
    //         "RWI",
    //         "LWI",
    //         "RWE",
    //         "LWE",   

    //         "REI",
    //         "LEI",
    //         "REE",
    //         "LEE",
    
    //     ]
    // },
    v111 :  {
        body_image : "body2.png",
        body_points : "body2-v111.json",
        body_width  : 600,
        points : ['nose', 'top_of_the_head', 'chin', 'neck', 'left_eye', 'right_eye', 'left_ear', 'right_ear', 'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle', 'left_bigtoe', 'right_bigtoe', 'left_heel', 'right_heel', 'neck_back', 'front_1', 'front_2', 'front_3', 'front_4', 'front_5', 'back_1', 'back_2', 'back_3', 'back_4', 'back_5', 'left_shoulder_hand', 'right_shoulder_hand', 'left_shoulder_neck', 'right_shoulder_neck', 'left_under_ear', 'right_under_ear', 'neck_top', 'back_neck_top', 'RWT', 'LWT', 'RWP', 'LWP', 'RWI', 'LWI', 'RWE', 'LWE', 'RKI', 'LKI', 'RKF', 'LKF', 'RKE', 'LKE', 'RKB', 'LKB', 'REI', 'LEI', 'REE', 'LEE', 'RAI', 'LAI', 'RAF', 'LAF', 'RAE', 'LAE', 'RAB', 'LAB', 'left_wrist', 'left_thumb1', 'left_thumb2', 'left_thumb3', 'left_thumb4', 'left_forefinger1', 'left_forefinger2', 'left_forefinger3', 'left_forefinger4', 'left_middle_finger1', 'left_middle_finger2', 'left_middle_finger3', 'left_middle_finger4', 'left_ring_finger1', 'left_ring_finger2', 'left_ring_finger3', 'left_ring_finger4', 'left_pinky_finger1', 'left_pinky_finger2', 'left_pinky_finger3', 'left_pinky_finger4', 'right_wrist', 'right_thumb1', 'right_thumb2', 'right_thumb3', 'right_thumb4', 'right_forefinger1', 'right_forefinger2', 'right_forefinger3', 'right_forefinger4', 'right_middle_finger1', 'right_middle_finger2', 'right_middle_finger3', 'right_middle_finger4', 'right_ring_finger1', 'right_ring_finger2', 'right_ring_finger3', 'right_ring_finger4', 'right_pinky_finger1', 'right_pinky_finger2', 'right_pinky_finger3', 'right_pinky_finger4']
    },
    v113 :  {
        body_image : "body2.png",
        body_points : "body2-v113.json",
        body_width  : 600,
        points : ['nose', 'top_of_the_head', 'chin', 'neck', 'left_eye', 'right_eye', 'left_ear', 'right_ear', 'left_shoulder', 'right_shoulder', 'left_elbow', 'right_elbow', 'left_hip', 'right_hip', 'left_knee', 'right_knee', 'left_ankle', 'right_ankle', 'left_bigtoe', 'right_bigtoe', 'left_heel', 'right_heel', 'neck_back', 'front_1', 'front_2', 'front_3', 'front_4', 'front_5', 'back_1', 'back_2', 'back_3', 'back_4', 'back_5', 'left_shoulder_hand', 'right_shoulder_hand', 'left_shoulder_neck', 'right_shoulder_neck', 'left_under_ear', 'right_under_ear', 'neck_top', 'back_neck_top', 'RWT', 'LWT', 'RWP', 'LWP', 'RWI', 'LWI', 'RWE', 'LWE', 'RKI', 'LKI', 'RKF', 'LKF', 'RKE', 'LKE', 'RKB', 'LKB', 'REI', 'LEI', 'REE', 'LEE', 'RAI', 'LAI', 'RAF', 'LAF', 'RAE', 'LAE', 'RAB', 'LAB', 'left_wrist', 'left_thumb1', 'left_thumb2', 'left_thumb3', 'left_thumb4', 'left_forefinger1', 'left_forefinger2', 'left_forefinger3', 'left_forefinger4', 'left_middle_finger1', 'left_middle_finger2', 'left_middle_finger3', 'left_middle_finger4', 'left_ring_finger1', 'left_ring_finger2', 'left_ring_finger3', 'left_ring_finger4', 'left_pinky_finger1', 'left_pinky_finger2', 'left_pinky_finger3', 'left_pinky_finger4', 'right_wrist', 'right_thumb1', 'right_thumb2', 'right_thumb3', 'right_thumb4', 'right_forefinger1', 'right_forefinger2', 'right_forefinger3', 'right_forefinger4', 'right_middle_finger1', 'right_middle_finger2', 'right_middle_finger3', 'right_middle_finger4', 'right_ring_finger1', 'right_ring_finger2', 'right_ring_finger3', 'right_ring_finger4', 'right_pinky_finger1', 'right_pinky_finger2', 'right_pinky_finger3', 'right_pinky_finger4',
            "left_smalltoe", "right_smalltoe",
        ]
    },

    hands :  {
        body_image : "hands2.png",
        body_points : "hands1.json",
        body_width  : 600,
        points : ['RWT', 'LWT', 'RWP', 'LWP', 'RWI', 'LWI', 'RWE', 'LWE', 
                  'left_wrist', 'left_thumb1', 'left_thumb2', 'left_thumb3', 'left_thumb4', 'left_forefinger1', 'left_forefinger2', 'left_forefinger3', 'left_forefinger4', 'left_middle_finger1', 'left_middle_finger2', 'left_middle_finger3', 'left_middle_finger4', 'left_ring_finger1', 'left_ring_finger2', 'left_ring_finger3', 'left_ring_finger4', 'left_pinky_finger1', 'left_pinky_finger2', 'left_pinky_finger3', 'left_pinky_finger4', 'right_wrist', 'right_thumb1', 'right_thumb2', 'right_thumb3', 'right_thumb4', 'right_forefinger1', 'right_forefinger2', 'right_forefinger3', 'right_forefinger4', 'right_middle_finger1', 'right_middle_finger2', 'right_middle_finger3', 'right_middle_finger4', 'right_ring_finger1', 'right_ring_finger2', 'right_ring_finger3', 'right_ring_finger4', 'right_pinky_finger1', 'right_pinky_finger2', 'right_pinky_finger3', 'right_pinky_finger4']
    },

}
export default versions
