import { Keypoints } from './skeleton-lib/keypoints'

export interface User {
    email: string,
    first_name: string,
    last_name: string,
    token: string,
    uid: number,
    is_admin:boolean,
    ver : string
};

export interface DataStats {
    annotated_today:number,
    annotated_total:number,
}

export type DataDesc = {
    image? : string,
    ver? : string,
    aid?    : number,
    fid?    : number,
    keypoints? : Keypoints,
    stats? : DataStats,
    ann_summary?:string,
    exam?:Keypoints,
    basedon? : number,
    fixedby? : number,
    hidden_exam? : number,
    hash?:string,
    flaggedout?:boolean,
}


export class ExamMetrics {
    iou = 0;
    maxError = 0;
    averageError = 0;
    rmsd=0;
    pass=false;
}

export type VAnnDesc = {
    sid  : number;
    path : string;
}

export type EVideoDesc = {
    uuid : string;
}


export type RVAnnSpec = {
    rvid  : number;
    url : string;
}

export type PEAnnData = {
    name : string;
    id:number
    uuid : string;
    eq : string[];
}


export type RVAnnAnn = {
    repeats? : number;
    status : "ok" | "bad";
}
