import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable,toJS } from "mobx";
import backend from '../../../backend';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

type MySelectProps = {
  field : string
  title : string
  options : string[]
  annotation: any
}


const AutocompleteMenu = observer((props : MySelectProps) => {
    return (
    <Autocomplete
        autoHighlight
        className='pe-input'
        options={props.options}
        sx={{ width: 600 }}
        renderInput={(params) => <TextField {...params} label={props.title} />}
        onChange={(event, values) => {
            props.annotation[props.field] = values
        }} 
        value={props.annotation[props.field]}
  />)
  })

  

class FAnnotator extends Component<{}> {
    fid : number = 0
    data : any|null = null
    coach_video_url : string = ""
    video_url : string = ""
    video : HTMLVideoElement | null = null
    videoSpeed = 2.0
    feedbackOptions : any = []

    query : string
    qcmode : boolean = false
    current : number = 0
    todo : any[]|null = null

    annotation: any  = {
        bad_video : false,    }

    constructor(props:{}) {
        super(props) 
        makeObservable(this, {
            coach_video_url : observable,
            video_url: observable,
            videoSpeed: observable,      
            annotation: observable,  
        });
        this.query = window.location.search
        if (this.query) {
            this.qcmode = true
        }
    }


    setSpeed(x:number) {
        this.videoSpeed = x
        if (this.video) {
            this.video.playbackRate = x 
        }
    }


    
    onChange = (e:React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>)=>{
        this.annotation[e.target.id] = e.target.value
    }

    onCheckboxChange = (e:React.ChangeEvent<HTMLInputElement>)=>{
        this.annotation[e.target.id] = e.target.checked
    }


    save = async () => {
        console.log("save:",toJS(this.annotation.feedback.id))
        await backend.feedback_save(this.fid, {feedback:toJS(this.annotation.feedback.id)})
        this.load()
    }
    
    async load() {
        if (this.qcmode) {
            //this.loadQC()
        } else {
            this.loadAnn()
        }
    }

    async getQcData() {
        /*
        if (!this.todo) {
            this.todo = await backend.peann_qcdata(this.query)
            //console.log("todo:",this.todo)
            this.current = 0
        }
        if (this.current<0 || !this.todo || this.current >= this.todo.length) {
            window.location.href = "/pea/admin"
            return null
        }
        return this.todo![this.current]
        */
    }

    next = () => {
        this.current++
        //this.loadQC()
    }
    prev = () => {
        this.current--
        //this.loadQC()
    }

    /*
    async loadQC() {
        const data : any = await this.getQcData()
        if (data) {
            //console.log("loadQC::::::::::::::",data)
            this.annotation = data.ann
            this.peid = data.peid
            //this.peaid = data.peaid
            this.data = data.data
            this.coach_video_url = `https://app.kemtai.com/media/exercises/${this.data!.uuid}/thumbnail-loop.webm`
            this.setSpeed(this.videoSpeed)
        }
    }
    */

    async loadAnn() {
        const data : any = await backend.feedback_todo()
        console.log("loadAnn::::::::::::::",data)

        if (data) {
            this.fid = data.fid
            this.data = data.data
            /*
            const feedbackOptions : {label:string, id:string }[] = []

            for (const fid in data.data.feedbacks) {
                feedbackOptions.push({label:data.data.feedbacks[fid], id:fid})
            }
            */


            this.feedbackOptions = data.data.feedbacks
            this.feedbackOptions.push({label:" ** Hard to select / bad video **", id:"bad-video"})

            this.annotation = {
                bad_video : false,
                feedback  : "" 
            }

           //console.log("load:",this.data)
            this.coach_video_url = `https://app.kemtai.com/media/exercises/${this.data!.uuid}/thumbnail-loop.webm`
            this.video_url       = `https://annotator.kemtai.com/rvvvv/feedbacks1/${this.data.id}/${this.data.video}`
            this.setSpeed(this.videoSpeed)
        }
    }

    componentDidMount() {
        this.load()
    }


    render() {
        console.log(this.coach_video_url)
        console.log(this.video_url)

        if (!this.data) {
            return null
        }
        
        return (
        <div key={this.data?.id}>
            <div style={{marginLeft:"70px"}}>
            <video ref={ref=>this.video=ref} src={this.video_url} muted controls loop autoPlay style={{height:"480px", margin:"10px"}}/>
            &nbsp;
            <video                     src={this.coach_video_url} muted controls loop autoPlay style={{height:"480px", margin:"10px"}}/>

            <div>
            <span style={{verticalAlign:"110%"}}>             
                            &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                            &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                    {[0.25,0.5,1,2,4].map(
                        v => <button onClick={e=>this.setSpeed(v)} key={v}
                                    style={ this.videoSpeed===v ? {color:"blue", fontWeight:"bold" }:{}}>
                            {v}x 
                            </button>
                    )}


                </span>
                </div> 
                </div>

        <div className='pe-form'>
             

        <AutocompleteMenu title='Select most relevant feedback'
             field='feedback' 
             options={this.feedbackOptions} annotation={this.annotation}></AutocompleteMenu>       
       

           {!this.qcmode &&<Button variant="contained" size="large" onClick={this.save}>Save</Button>}
           {this.qcmode && <Button variant="contained" size="large" onClick={this.next}>Next</Button>}
           {this.qcmode && this.current>0 && <Button variant="contained" size="large" onClick={this.prev}>Prev</Button>}

    </div>                
    </div>
        )
    }

}
export default observer(FAnnotator);
