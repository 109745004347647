import React, { Component } from 'react';
import { observer } from "mobx-react";
import { observable, makeObservable } from "mobx";
import { VAnnDesc } from '../../api_types';
import backend from '../../backend';


class VAnnotator extends Component<{}> {
    sid : number = 0
    path : string = ""
    video : HTMLVideoElement | null = null
    videoSpeed = 1.0

    constructor(props:{}) {
        super(props) 
        makeObservable(this, {
            path : observable,
            videoSpeed: observable,        
        });

    }


    setSpeed(x:number) {
        this.videoSpeed = x
        if (this.video) {
            this.video.playbackRate = x 
        }
    }

    get videurl() : string{
        return "https://annotator.kemtai.com/" + this.path // "https://kemtai-web-annotator-work.s3.eu-central-1.amazonaws.com/SKEL-VIDEO/video-ann-test-1/2020-11-14:113044:a2payema.work@gmail.com:PelvisLift:2b251c2330c578:1605364244452.skel.webm"
    }

    save(ann:string) {
        backend.vsave(this.sid, ann)
        this.load()
    }    

    async load() {
        const data : VAnnDesc| null = await backend.vtodo()
        if (data) {
            this.sid = data.sid
            this.path = data.path
            this.setSpeed(this.videoSpeed)
        }
    }

    componentDidMount() {
        this.load()
    }

    render() {
        console.log(`Anootator.render  ${this.videurl}` )

        return (
                <div>
                     <video ref={ref=>this.video=ref} src={this.videurl} autoPlay controls loop width="1200px" height="800px"/>
                     <div>
                     <img className="button" src="/icons/icons8-red-circle-48.png"    alt="" onClick={()=>this.save("bad")} />
                     <img className="button" src="/icons/icons8-yellow-circle-48.png" alt="" onClick={()=>this.save("borderline")}  />
                     <img className="button" src="/icons/icons8-green-circle-48.png"  alt="" onClick={()=>this.save("ok")} />
                     <span style={{verticalAlign:"110%"}}>             
                                  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
                                  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp;  &nbsp; 
        {[0.25,0.5,1,2,4].map(
             v => <button onClick={e=>this.setSpeed(v)} 
                         style={ this.videoSpeed===v ? {color:"blue", fontWeight:"bold" }:{}}>
                  {v}x 
                  </button>
        )}        
                     </span>
                     </div><div>
                     </div>
                    
                     <div>
                        <p>video:{this.videurl}</p>
                       {/* <p>speed: {this.videoSpeed}</p> */}
                     </div>
                    
                </div>
        )
    }

}
export default observer(VAnnotator);
